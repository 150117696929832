<template>
  <div class="market_container">
    <TopNav />

    <div class="markets ptb70">
      <div class="container">
        <div class="row">
          <h1 class="col text-dark">Pasar</h1>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="markets-pair-list">
              <MarketTable />
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
// node_modules
import Cookies from "js-cookie";

// applibs
import themain from "@/applibs/themain.js";
import thestore from "@/applibs/thestore.js";

// components
import TopNav from "@/components/global/TopNav.vue";
import Footer from "@/components/global/Footer.vue";
import MarketTable from "@/components/global/MarketTable.vue";

export default {
  components: {
    TopNav,
    Footer,
    MarketTable,
  },
  data() {
    return {
      themain,
      token: Cookies.get("jwt"),
      usid: Cookies.get("usid"),
    };
  },

  async beforeMount() {
    var self = this;
    // cek expire token
    if (self.token) {
      var res = await thestore.postCheckToken(self.token);
      if (res.success == false) {
        Cookies.remove("jwt");
        Cookies.remove("usid");
        self.$router.go();
      }
    }
  },
};
</script>

<style scoped>
.market_container {
  height: 100%;
}

.markets {
  min-height: 700px !important;
}
h1 {
  color: #000;
}
</style>
