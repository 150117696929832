<template>
  <div class="ex_container">
    <TopNav />

    <!-- <h2>{{ tv_host }}</h2> -->

    <div class="container-fluid p-0">
      <div class="row no-gutters">
        <Summary
          :marketData="market"
          :currencyAObj="currencyAObj"
          :currencyBObj="currencyBObj"
        />
      </div>

      <div class="row no-gutters">
        <MarketSection />
        <div class="col-md-6">
          <div class="market-history market-order" v-bind:id="`chart-tab`">
            <div class="heading">
              <div
                class="mainchart-head active cursor-pointer"
                @click="themain.openTabNav('chart-tab', 'mainchart')"
                style="color: #fff; float: left; margin-top: -8px"
              >
                <!-- Candle -->
                <img
                  src="/images/candle-stick-logo.png"
                  alt="cs-logo"
                  style="height: 20px; margin-left: 4px"
                />
              </div>

              <div
                class="marketdepth-head cursor-pointer d-flex"
                style="color: #fff; float: right; margin-top: -8px"
              >
                <div class="mr-4 show_btn">
                  <svg
                    @click="showModalChart = true"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    class="css-1t4k2nh"
                  >
                    <path
                      d="M14.121 12l3.44-3.44L21 12V3h-9l3.44 3.44L12 9.878l2.121 2.12zM3 12l3.44 3.44L9.878 12 12 14.12l-3.44 3.44L12 21H3v-9z"
                      fill="#758696"
                    ></path>
                  </svg>
                </div>
                <svg
                  @click="themain.openTabNav('chart-tab', 'marketdepth')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                >
                  <path
                    d="M1.293 7.293C1 7.586 1 8.057 1 9V15C1 15.943 1 16.414 1.293 16.707C1.586 17 2.057 17 3 17C3.943 17 4.414 17 4.707 16.707C5 16.414 5 15.943 5 15V9C5 8.057 5 7.586 4.707 7.293C4.414 7 3.943 7 3 7C2.057 7 1.586 7 1.293 7.293Z"
                    fill="#FF666D"
                  />
                  <path
                    opacity="0.4"
                    d="M15.293 0.293C15 0.586 15 1.057 15 2V15C15 15.943 15 16.414 15.293 16.707C15.586 17 16.057 17 17 17C17.943 17 18.414 17 18.707 16.707C19 16.414 19 15.943 19 15V2C19 1.057 19 0.586 18.707 0.293C18.414 -2.98023e-08 17.943 0 17 0C16.057 0 15.586 -2.98023e-08 15.293 0.293Z"
                    fill="#21DF82"
                  />
                  <path
                    opacity="0.7"
                    d="M8 5C8 4.057 8 3.586 8.293 3.293C8.586 3 9.057 3 10 3C10.943 3 11.414 3 11.707 3.293C12 3.586 12 4.057 12 5V15C12 15.943 12 16.414 11.707 16.707C11.414 17 10.943 17 10 17C9.057 17 8.586 17 8.293 16.707C8 16.414 8 15.943 8 15V5Z"
                    fill="#21DF82"
                  />
                  <path
                    d="M1 19.25C0.801088 19.25 0.610322 19.329 0.46967 19.4697C0.329018 19.6103 0.25 19.8011 0.25 20C0.25 20.1989 0.329018 20.3897 0.46967 20.5303C0.610322 20.671 0.801088 20.75 1 20.75H19C19.1989 20.75 19.3897 20.671 19.5303 20.5303C19.671 20.3897 19.75 20.1989 19.75 20C19.75 19.8011 19.671 19.6103 19.5303 19.4697C19.3897 19.329 19.1989 19.25 19 19.25H1Z"
                    fill="#21DF82"
                  />
                </svg>
                <!-- <span>Depth</span> -->
              </div>
            </div>

            <div class="tab-content">
              <div
                class="tab-pane fade mainchart-body show active"
                role="tabpanel"
              >
                <div class="main-chart">
                  <!-- TradingView Widget BEGIN -->
                  <!-- TODO: Height 550 harus diganti untuk screen kecil -->
                  <div
                    class="tradingview-widget-container"
                    ref="tviframe"
                    style="height: 550px"
                  >
                    <iframe
                      :src="`${tv_host}/?pair=${market_pair}&width=${tvWidth}&height=${tvHeight}`"
                      style="width: 100%; height: 550px; border-width: 0px"
                    ></iframe>
                  </div>
                  <!-- TradingView Widget END -->
                </div>
              </div>
              <div class="tab-pane marketdepth-body fade" role="tabpanel">
                <iframe
                  :src="`${tv_host}/market-depth?pair=${market_pair}&width=${tvWidth}&height=${tvHeight}`"
                  style="width: 100%; height: 550px; border-width: 0px"
                ></iframe>
              </div>
            </div>
          </div>

          <div
            class="market-trade"
            style="padding: 14px; padding-top: 12px; padding-bottom: 12px"
          >
            <div class="d-flex justify-content-between">
              <div id="comp-buy-form">
                <div
                  id="com-buy-close-form"
                  @click="smallScreenCloseBuyForm"
                  class="text-dark text-right"
                  style="cursor: pointer"
                >
                  x
                </div>
                <BuyFormSection
                  :marketData="market"
                  :currencyAObj="currencyAObj"
                  :currencyBObj="currencyBObj"
                  :handleFormatDisplay="handleFormatDisplay"
                  :isFormatNumber="isFormatNumber"
                  v-if="token != null && token != ''"
                />
                <div v-else class="col-12">
                  <button
                    class="btn buy"
                    @click="$router.push({ path: '/auth/register' })"
                  >
                    Daftar
                  </button>
                </div>
              </div>

              <div id="comp-sell-form">
                <div
                  id="com-sell-close-form"
                  @click="smallScreenCloseSellForm"
                  class="text-dark text-right"
                  style="cursor: pointer"
                >
                  x
                </div>
                <SellFormSection
                  :marketData="market"
                  :currencyAObj="currencyAObj"
                  :currencyBObj="currencyBObj"
                  :handleFormatDisplay="handleFormatDisplay"
                  :isFormatNumber="isFormatNumber"
                  v-if="token != null && token != ''"
                />
                <div v-else class="col-12">
                  <button
                    class="btn sell"
                    @click="$router.push({ path: '/auth/login' })"
                  >
                    Masuk
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="order-book" id="orderbook-history-tab">
            <ul class="nav nav-pills">
              <li class="nav-item">
                <div
                  class="nav-link orderbook-head active cursor-pointer"
                  @click="
                    themain.openTabNav('orderbook-history-tab', 'orderbook')
                  "
                >
                  Buku Pesanan
                </div>
              </li>
              <li class="nav-item">
                <div
                  class="nav-link history-head cursor-pointer"
                  @click="
                    themain.openTabNav('orderbook-history-tab', 'history')
                  "
                >
                  Transaksi Terbaru
                </div>
              </li>
            </ul>
            <div class="tab-content">
              <div class="tab-pane fade orderbook-body show active">
                <div class="dex_filter">
                  <div class="dex_filter-wrapper">
                    <button
                      @click="changeOrderView('orderBook')"
                      class="css-vpi5k2"
                      :class="{ order_active: isOrderBook }"
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width="7" height="7" fill="#F6465D" />
                        <rect x="9" width="7" height="4" fill="#707A8A" />
                        <rect x="9" y="6" width="7" height="4" fill="#707A8A" />
                        <rect
                          x="9"
                          y="12"
                          width="7"
                          height="4"
                          fill="#707A8A"
                        />
                        <rect y="9" width="7" height="7" fill="#0ECB81" />
                      </svg>
                    </button>

                    <button
                      @click="changeOrderView('orderBuy')"
                      class="css-vpi5k2"
                      :class="{ order_active: isOrderBuy }"
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect x="9" width="7" height="4" fill="#707A8A" />
                        <rect x="9" y="6" width="7" height="4" fill="#707A8A" />
                        <rect
                          x="9"
                          y="12"
                          width="7"
                          height="4"
                          fill="#707A8A"
                        />
                        <rect width="7" height="16" fill="#0ECB81" />
                      </svg>
                    </button>

                    <button
                      @click="changeOrderView('orderSell')"
                      class="css-vpi5k2"
                      :class="{ order_active: isOrderSell }"
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect x="9" width="7" height="4" fill="#707A8A" />
                        <rect x="9" y="6" width="7" height="4" fill="#707A8A" />
                        <rect
                          x="9"
                          y="12"
                          width="7"
                          height="4"
                          fill="#707A8A"
                        />
                        <rect width="7" height="16" fill="#F6465D" />
                      </svg>
                    </button>
                  </div>

                  <div
                    @click="handleDigitMenu"
                    @mouseleave="isActive = false"
                    class="dex_digit"
                  >
                    <div class="digit_item">
                      <span>{{ valueSelected }}</span>
                      <svg
                        width="15"
                        height="7"
                        viewBox="0 0 19 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M18.2195 0L9.10975 10.9317L0 0H18.2195Z"
                          fill="#707A8A"
                        />
                      </svg>
                    </div>

                    <div class="digit_lists" :class="{ menu_active: isActive }">
                      <span @click="changeDigitFloat('0.0001')">0.0001</span
                      ><span @click="changeDigitFloat('0.001')">0.001</span
                      ><span @click="changeDigitFloat('0.01')">0.01</span
                      ><span @click="changeDigitFloat('0.1')">0.1</span
                      ><span @click="changeDigitFloat('1')">1</span>
                    </div>
                  </div>
                </div>
                <table class="table">
                  <thead :class="{ order_hide: isOrderBuy }">
                    <tr>
                      <th>Harga({{ simbol_b }})</th>
                      <th>Jumlah({{ simbol_a }})</th>
                      <th>Total({{ simbol_b }})</th>
                    </tr>
                  </thead>
                  <tbody
                    v-bind:id="`obook_sell`"
                    :class="{ order_hide: isOrderBuy }"
                  >
                    <tr
                      v-for="item in sells"
                      :key="item.id"
                      :class="`red-bg-${item.percent}`"
                      @click="orderbookRowClicked('sell', item)"
                    >
                      <td class="red">
                        {{
                          parseFloat(item.market_position).toLocaleString(
                            "en-US",
                            {
                              minimumFractionDigits: floatDigit,
                            }
                          )
                        }}
                      </td>
                      <td>
                        {{
                          parseFloat(item.market_amount).numberFormat(
                            currencyAObj.decimal_display
                          )
                        }}
                      </td>
                      <td>
                        {{
                          parseFloat(
                            item.market_result_including_fee
                          ).numberFormat(currencyBObj.decimal_display)
                        }}
                      </td>
                    </tr>
                  </tbody>
                  <tbody class="ob-heading">
                    <tr>
                      <td>
                        <span>Harga Terakhir</span>
                        <div
                          :class="`${last_price_color}`"
                          style="font-size: 1.2rem"
                        >
                          {{
                            parseFloat(last_price).toLocaleString("en-US", {
                              minimumFractionDigits: floatDigit,
                            })
                          }}
                        </div>
                      </td>
                      <td>
                        <span>Vol</span>
                        {{ parseFloat(vol_24h).numberFormat(2) }}
                      </td>
                      <!-- <td :class="`${price_color}`">
                        <span>Change</span>
                        {{parseFloat(change_24h).numberFormat(2)}}%
                      </td> -->
                    </tr>
                  </tbody>
                  <tbody :class="{ order_hide: isOrderSell }">
                    <tr
                      v-for="item in buys"
                      :key="item.id"
                      :class="`green-bg-${item.percent}`"
                      @click="orderbookRowClicked('buy', item)"
                    >
                      <td class="green">
                        {{
                          parseFloat(item.market_position).toLocaleString(
                            "en-US",
                            {
                              minimumFractionDigits: floatDigit,
                            }
                          )
                        }}
                      </td>

                      <td>
                        {{
                          parseFloat(
                            item.market_result_including_fee
                          ).numberFormat(currencyAObj.decimal_display)
                        }}
                      </td>
                      <td>
                        {{
                          parseFloat(item.market_amount).numberFormat(
                            currencyBObj.decimal_display
                          )
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="tab-pane fade history-body" role="tabpanel">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Harga({{ simbol_b }})</th>
                      <th>Jumlah({{ simbol_a }})</th>
                      <th>Time</th>
                    </tr>
                  </thead>
                  <tbody class="history-ob-tbody">
                    <tr v-for="item in histories" :key="item.id">
                      <td :class="historyClass(item)">
                        {{
                          parseFloat(item.mclose_position).numberFormat(
                            currencyBObj.decimal_display
                          )
                        }}
                      </td>
                      <td>
                        {{
                          parseFloat(item.mclose_symbol_a).numberFormat(
                            currencyAObj.decimal_display
                          )
                        }}
                      </td>
                      <td
                        :title="
                          moment(item.created_at).format('YYYY-MM-DD HH:mm:ss')
                        "
                      >
                        {{ moment(item.created_at).format("HH:mm:ss") }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="market-newsd d-flex flex-column h-100">
            <h2 class="heading">Berita</h2>
            <div
              v-if="articles.length < 1"
              class="d-flex flex-column justify-content-center align-items-center flex-fill py-5"
            >
              <svg
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="#787878"
                  stroke-linecap="round"
                  stroke-width="2"
                  d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"
                />
              </svg>
              <p class="mt-1 mb-0" style="font-weight: 500">
                Belum ada berita tersedia
              </p>
            </div>
            <div v-else class="d-flex flex-column justify-content-between flex-fill">
              <ul>
                <li v-for="item in articles" :key="item.id">
                  <a :href="item.ext_link" target="_blank">
                    <strong>{{ item.title }}</strong>
                    {{ item.body }}
                    <span>{{ item.created_at }}</span>
                  </a>
                </li>
              </ul>
              <div class="row py-2">
                <div class="col d-flex justify-content-center">
                  <div class="btn btn-sm btn-gradient">Muat Lebih Banyak</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- group tab untuk user yang login -->
        <div class="col-md-9" v-if="token">
          <div class="market-history market-order" v-bind:id="`my-tab`">
            <ul class="nav nav-pills" role="tablist">
              <li class="nav-item">
                <div
                  class="nav-link open-orders-head active cursor-pointer"
                  @click="themain.openTabNav('my-tab', 'open-orders')"
                >
                  Pesanan
                </div>
              </li>
              <li class="nav-item">
                <div
                  class="nav-link closed-orders-head cursor-pointer"
                  @click="themain.openTabNav('my-tab', 'closed-orders')"
                >
                  Riwayat Pesanan
                </div>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link balance-head cursor-pointer"
                  @click="themain.openTabNav('my-tab', 'balance')"
                >
                  Dompet
                </a>
              </li>
            </ul>
            <div class="tab-content">
              <div
                class="tab-pane fade open-orders-body show active"
                role="tabpanel"
              >
                <MyOpenOrders
                  :marketData="market"
                  :currencyAObj="currencyAObj"
                  :currencyBObj="currencyBObj"
                />
              </div>
              <div class="tab-pane closed-orders-body fade" role="tabpanel">
                <MyClosedOrders
                  :marketData="market"
                  :currencyAObj="currencyAObj"
                  :currencyBObj="currencyBObj"
                />
              </div>
              <div class="tab-pane fade balance-body" role="tabpanel">
                <BalancesTable />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- hanya tab tampil pada saat screen kecil -->
    <div class="fixed-bottom" id="bottom-navbar">
      <div class="container-fluid">
        <!-- buy dan sell jika punya token -->
        <div class="row" v-if="token">
          <div class="col-6">
            <button class="btn btn-buy" @click="smallScreenShowBuyForm">
              Beli
            </button>
          </div>
          <div class="col-6">
            <button class="btn btn-sell" @click="smallScreenShowSellForm">
              Jual
            </button>
          </div>
        </div>

        <!-- register dan login jika tidak punya token -->
        <div class="row" v-else>
          <div class="col-6">
            <button
              class="btn btn-buy"
              @click="$router.push({ path: '/auth/register' })"
            >
              Daftar
            </button>
          </div>
          <div class="col-6">
            <button
              class="btn btn-sell"
              @click="$router.push({ path: '/auth/login' })"
            >
              Masuk
            </button>
          </div>
        </div>
      </div>
    </div>

    <notifications />

    <div v-if="showModalChart">
      <Teleport to="body">
        <!-- use the modal component, pass in the prop -->
        <ModalChart show="showModalChart" @close="showModalChart = false">
          <template #body>
            <div class="close_btn">
              <svg
                @click="showModalChart = false"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                class="css-1t4k2nh"
              >
                <path
                  d="M4.621 21.5l3.44-3.439 3.439 3.44v-9h-9l3.44 3.439-3.44 3.44 2.121 2.12zM12.5 2.5l3.44 3.44 3.439-3.44L21.5 4.622l-3.44 3.44L21.5 11.5h-9v-9z"
                  fill="#758696"
                ></path>
              </svg>
            </div>
            <iframe
              :src="`${tv_host}/?pair=${market_pair}&width=${tvWidthF}&height=${tvHeightF}`"
              style="width: 100%; height: 864px; border-width: 0px"
            ></iframe>
          </template>
        </ModalChart>
      </Teleport>
    </div>
  </div>
</template>

<script>
// node_modules
import moment from "moment";
import Cookies from "js-cookie";
import { mapState } from "vuex";

// applibs
import themain from "@/applibs/themain.js";
import thestore from "@/applibs/thestore.js";

// components
import TopNav from "@/components/global/TopNav.vue";
import BuyFormSection from "@/components/markets/Exchange/BuyFormSection.vue";
import SellFormSection from "@/components/markets/Exchange/SellFormSection.vue";
import MarketSection from "@/components/markets/Exchange/MarketSection.vue";
import MyOpenOrders from "@/components/markets/Exchange/MyOpenOrders.vue";
import MyClosedOrders from "@/components/markets/Exchange/MyClosedOrders.vue";
import Summary from "@/components/markets/Exchange/Summary.vue";
import BalancesTable from "@/components/global/BalancesTable.vue";
import ModalChart from "../../components/modals/ModalChart.vue";

export default {
  components: {
    TopNav,
    BuyFormSection,
    SellFormSection,
    MarketSection,
    MyOpenOrders,
    MyClosedOrders,
    Summary,
    BalancesTable,
    ModalChart,
  },
  data() {
    return {
      moment,
      themain,

      market_id: this.$route.params.market_id,
      market_pair: this.$route.params.market_pair,

      token: Cookies.get("jwt"),
      usid: Cookies.get("usid"),

      last_price: 0,
      price_buy: 0,
      price_sell: 0,
      vol_24h: 0,
      change_24h: 0,
      price_color: "green",
      last_price_color: "green",
      last_price_before: 0,

      simbol_a: "",
      simbol_b: "",

      currencyAObj: {},
      currencyBObj: {},

      market: {
        pair: this.$route.params.market_pair,
      },
      sells: [],
      buys: [],
      histories: [],

      tvWidth: 0,
      tvHeight: 550,

      tvWidthF: `100%`,
      tvHeightF: `1000vh`,

      tv_host: process.env.VUE_APP_TV,

      biggest_qty_b_db_sell: 0,
      biggest_qty_b_db_buy: 0,
      biggest_qty_b_db: 0,

      articles: [],

      ubalances: {},

      isActive: false,
      isOrderActive: false,
      isOrderBook: true,
      isOrderSell: false,
      isOrderBuy: false,

      showModalChart: false,
      isFormatNumber: false,
    };
  },

  sockets: {
    connect: function () {
      // PERHATIAN: JANGAN DI COMMENT KARENA AKAN MENYEBABKAN ERROR
      console.log("socket connected");
    },

    notif: function (data) {
      var self = this;
      if (data.uid == self.usid) {
        self.$notify({
          title: data.message,
        });
      }
    },

    notif_obooks_result: function (data) {
      var self = this;

      data.data.forEach(function (el) {
        if (el.uid == self.usid) {
          self.$notify({
            title: `order #${el.id} result: ${el.result}`,
          });

          // kalau ada yang close, maka disampaikan kepada component myorderbook
          // karena disana tidak dapat mendeteksi close
          if (el.status == "close") {
            self.emitter.emit("fe-myobook-close", {
              data: el,
            });
          }
        }
      });
    },

    // lihat keterangan pada socket buys
    sells: function (data) {
      var self = this;

      // console.log('data', data);

      if (data.pair == self.market_pair) {
        var datas = JSON.parse(data.sells);

        // // letakkan data sells
        // self.sells = datas.sort(function(a,b) {
        //   return parseFloat(b.market_position) - parseFloat(a.market_position);
        // });

        self.olahSells(datas);

        if (self.sells.length > 0) {
          self.price_sell = self.sells[0].market_position;
        } else {
          self.price_sell = 0;
        }

        self.emitter.emit("fe-sells", {
          data: self.sells,
        });

        self.recalcOrderBook();
      }
    },

    // data yang didapat terlihat seperti berikut
    // {pair: 'BTC_IDR', buys: '[{"id":174,"orang_id":11,"market_amount":"221999.9…:"open","created_at":"2023-03-06T22:59:52.196Z"}]'}
    // untuk property buys, ini adadalah array. Jadi kita harus urutkan lagi
    buys: function (data) {
      var self = this;

      if (data.pair == self.market_pair) {
        var datas = JSON.parse(data.buys);

        // disini buys di sort dengan benar berdasarkan harga dan idnya
        self.olahBuys(datas);

        // kita tentukan harga buy pada row pertama disini
        // harga buy pertama artinya adalah harga buy yang akan ditampilkan di summary
        if (self.buys.length > 0) {
          self.price_buy = self.buys[0].market_position;
        } else {
          self.price_buy = 0;
        }

        self.recalcOrderBook();

        self.emitter.emit("fe-buys", {
          data: self.buys,
        });
      }
    },

    /**
     * History close dari orderbook
     * Bagian ini juga dipakai oleh myobooks untuk menutup/hide open orderbook jika ada yang sudah close
     */
    histories: function (data) {
      var self = this;
      if (data.pair == self.market_pair) {
        var newhistories = data.data.concat(self.histories);
        self.histories = newhistories;
        self.emitter.emit("fe-histories", {
          data: self.histories,
        });
      }
    },

    market_update: function (data) {
      var self = this;

      var market = JSON.parse(data.jsontable);

      if (data.pair == self.market_pair) {
        self.last_price = market.last_price;
        self.price_buy = market.last_bid;
        self.price_sell = market.last_ask;
        self.change_24h = market.change_24h;

        if (market.change_24h >= 0) {
          self.price_color = "green";
        } else {
          self.price_color = "red";
        }

        self.vol_24h = market.vol_24h;

        // setelah mendapat last price yang baru, maka kita buat perhitungan
        self.lastPriceColorCalculation(market.last_price);

        self.emitter.emit("fe-market_update", {
          pair: data.pair,
          data: market,
        });
      }
    },

    ubal: function (data) {
      var self = this;
      var obj = JSON.parse(data);
      obj.forEach(function (el) {
        if (parseInt(self.usid) == el.u) {
          self.ubalances = el;
          self.emitter.emit("fe-ubalances", {
            data: el,
          });
        }
      });
    },

    last_ticker: function (data) {
      // PERHATIAN: JANGAN DI COMMENT KARENA AKAN MENYEBABKAN ERROR
      console.log("last_ticker", data);
    },
  },

  async beforeMount() {
    var self = this;

    var market_pair_arr = self.market_pair.split("_");
    self.simbol_a = market_pair_arr[0];
    self.simbol_b = market_pair_arr[1];

    // cek expire token
    if (self.token) {
      var res = await thestore.postCheckToken(self.token);
      if (res.success == false) {
        Cookies.remove("jwt");
        Cookies.remove("usid");
        self.$router.go();
      }
    }
  },

  computed: {
    ...mapState(["valueSelected", "floatDigit"]),
  },

  async mounted() {
    var self = this;

    // ambil market
    var marketRes = await thestore.getMarket(self.market_pair);
    self.market = marketRes;
    // console.log("getMarket", marketRes);

    var tvWidthRes = self.$refs.tviframe.clientWidth;
    self.tvWidth = tvWidthRes;

    self.getDataForThisPageFirstTime();

    // ambil data dari server pertama kali dan letakan pada view
    self.histories = await thestore.getHistoriesFirstTime(self.market_pair);

    // ambil data-data dari ccurrency
    await self.getCurrenciesData();

    const height = window.screen.height - 65;
    self.tvHeightF = `${height}px`;
    // console.log("full height", height);
  },
  methods: {
    // fungsi untuk display format number pada Buy/sell form
    handleFormatDisplay(value) {
      var self = this;
      self.isFormatNumber = value;
    },

    // Digunakan untuk mengambil kedua data pair
    async getCurrenciesData() {
      var self = this;

      // simbol_a
      var res_a = await thestore.getCurrencies(1, 1, "id", "asc", [
        { field: "ccurrency_symbol", op: "equal", value: self.simbol_a },
      ]);
      self.currencyAObj = res_a.datas[0];
      // console.log("currencyAObj", self.currencyAObj);

      // simbol_b
      var res_b = await thestore.getCurrencies(1, 1, "id", "asc", [
        { field: "ccurrency_symbol", op: "equal", value: self.simbol_b },
      ]);
      self.currencyBObj = res_b.datas[0];
      // console.log("currencyBObj", self.currencyBObj);
    },

    sellElScrollToBottom() {
      setTimeout(() => {
        var objDiv = document.getElementById("obook_sell");
        objDiv.scrollTop = objDiv.scrollHeight;
      }, 100);
    },

    recalcOrderBook() {
      var self = this;

      // cari qty max dari buys
      var max_obj_buys = { qty_b_db: 0 };
      if (self.buys.length > 0) {
        max_obj_buys = self.buys.reduce(function (prev, current) {
          return parseFloat(prev.market_amount) >
            parseFloat(current.market_amount)
            ? prev
            : current;
        });
      }

      // cari qty max dari sells
      var max_obj_sells = { qty_b_db: 0 };
      if (self.sells.length > 0) {
        max_obj_sells = self.sells.reduce(function (prev, current) {
          return parseFloat(prev.market_result_including_fee) >
            parseFloat(current.market_result_including_fee)
            ? prev
            : current;
        });
      }

      // cari manakah yang paling besar, apakah qty buy atau qty sell?
      var biggest = 0;
      if (
        parseFloat(max_obj_sells.market_result_including_fee) >
        parseFloat(max_obj_buys.market_amount)
      ) {
        biggest = parseFloat(max_obj_sells.market_result_including_fee);
      } else {
        biggest = parseFloat(max_obj_buys.market_amount);
      }

      // karena data buys price dalam string, kita susah mengurutkannya
      // ada kalanya mengurutkan string menjadi masalah
      var buys_obj = [];
      self.buys.forEach((element) => {
        // buat persentase
        element.percent = ((element.market_amount / biggest) * 100).toFixed(0);
        element.price_db_float = parseFloat(element.market_position);

        buys_obj.push(element);
      });

      // karena data sells price dalam string, kita susah mengurutkannya
      // ada kalanya mengurutkan string menjadi masalah
      var sells_obj = [];
      self.sells.forEach((element) => {
        // buat persentase
        element.percent = (
          (element.market_result_including_fee / biggest) *
          100
        ).toFixed(0);
        element.price_db_float = parseFloat(element.market_position);

        sells_obj.push(element);
      });

      self.sellElScrollToBottom();
    },

    orderbookRowClicked(buy_or_sell, obj) {
      var self = this;
      self.emitter.emit("fe-obook_clicked", {
        data: {
          kind: buy_or_sell,
          obj,
        },
      });
    },

    smallScreenShowBuyForm() {
      var el = document.getElementById("comp-buy-form");
      el.style.display = "block";
      el.scrollIntoView({ behavior: "smooth", block: "end" });

      var elNav = document.getElementById("bottom-navbar");
      elNav.style.display = "none";
    },
    smallScreenCloseBuyForm() {
      var el = document.getElementById("comp-buy-form");
      el.style.display = "none";

      var elNav = document.getElementById("bottom-navbar");
      elNav.style.display = "block";
    },
    smallScreenShowSellForm() {
      var el = document.getElementById("comp-sell-form");
      el.style.display = "block";
      el.scrollIntoView({ behavior: "smooth", block: "end" });

      var elNav = document.getElementById("bottom-navbar");
      elNav.style.display = "none";
    },
    smallScreenCloseSellForm() {
      var el = document.getElementById("comp-sell-form");
      el.style.display = "none";

      var elNav = document.getElementById("bottom-navbar");
      elNav.style.display = "block";
    },

    async getArticles() {
      var self = this;
      var res = await thestore.getArticles(1, 10, "id", "desc", []);
      self.articles = res.datas;
    },

    historyClass(item) {
      if (item.aggressor == "buyer") {
        return "green";
      } else {
        return "red";
      }
    },

    async getDataForThisPageFirstTime() {
      var self = this;

      var res = await thestore.getDataForExchangePageFirstTime(
        self.market_pair
      );
      // console.log("res n data first time", res);

      self.olahBuys(res.buys); // data buys diolah dulu supaya bisa diurutkan dengan benar
      self.emitter.emit("fe-buys", {
        data: self.buys,
      });
      // self.sells = res.sells;
      self.olahSells(res.sells);
      self.emitter.emit("fe-sells", {
        data: self.sells,
      });

      self.last_price = res.market.last_price;
      self.price_buy = res.market.last_bid;
      self.price_sell = res.market.last_ask;
      self.vol_24h = res.market.vol_24h;
      self.change_24h = res.market.change_24h;

      self.emitter.emit("fe-market_update", {
        pair: self.market_pair,
        data: res.market,
      });

      self.recalcOrderBook();
    },

    olahBuys(datas) {
      var self = this;

      // olah dulu jadikan string
      var nudatas = [];
      datas.forEach(function (el) {
        var anu = el;
        anu["urut"] = `${el.market_position}_${el.id}`;
        nudatas.push(anu);
      });
      // console.log("nudatas", nudatas);

      self.buys = nudatas.sort((a, b) => b.urut.localeCompare(a.urut));
    },

    olahSells(datas) {
      var self = this;

      // olah dulu jadikan string
      var nudatas = [];
      datas.forEach(function (el) {
        var anu = el;
        anu["urut"] = `${el.market_position}_${el.id}`;
        nudatas.push(anu);
      });
      // console.log("nudatas", nudatas);

      self.sells = nudatas.sort((a, b) => b.urut.localeCompare(a.urut));
    },

    lastPriceColorCalculation(new_price) {
      var self = this;

      // console.log("new_price", new_price);
      // console.log("self.last_price_before", self.last_price_before);

      // setelah mendapat last price yang baru, maka kita buat perhitungan
      if (parseFloat(new_price) > parseFloat(self.last_price_before)) {
        self.last_price_color = "green";
      } else {
        self.last_price_color = "red";
      }
      self.last_price_before = parseFloat(new_price);
    },

    handleDigitMenu() {
      var self = this;

      self.isActive = !self.isActive;
    },

    changeDigitFloat(val) {
      var self = this;

      this.$store.dispatch("changeDigitFloat", val);

      self.sells();
    },

    changeOrderView(e) {
      var self = this;

      self.isOrderBook = e === "orderBook";
      self.isOrderBuy = e === "orderBuy";
      self.isOrderSell = e === "orderSell";
    },
  },
};
</script>

<style scoped>
.ex_container {
  background-color: #fff;
}

/*
bagian ini membuat table yang berantakan menjadi rapi
lihat diskusi ini https://stackoverflow.com/questions/41534794/table-rows-not-aligning
sample berantakan: https://jsfiddle.net/fk6fnto4/
dirapikan: https://jsfiddle.net/fk6fnto4/1/
 */
.order-book thead tr {
  display: table;
  table-layout: fixed;
  width: 100%;
}
.order-book tbody tr {
  display: table;
  table-layout: fixed;
  width: 100%;
}
.order-book td,
th {
  padding: 5px;
  text-align: right;
}

.dex_filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  margin-top: 15px;
  padding: 0 10px;
}

.css-vpi5k2 svg {
  opacity: 0.2;
}

.order_active svg {
  opacity: 1;
}

.css-vpi5k2 {
  background: none;
  border: none;
}

.digit_item {
  width: 60px;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-end;
}

.digit_item span {
  color: #000;
}

.dex_digit {
  position: relative;
  cursor: pointer;
}

.digit_lists {
  position: absolute;
  /* margin-right: 10px; */
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;

  background-color: #fff;
  border: 1px solid gray;
  border-radius: 3px;
  width: 70px;
  z-index: 999;

  transition-duration: 0.5s;
}

.menu_active {
  display: flex;
}

.digit_lists span {
  color: #000;
  width: 100%;
  display: flex;
  justify-content: center;
}

.digit_lists span:hover {
  background-color: #ef7f1a;
}

.order_hide {
  display: none !important;
}

.close_btn {
  position: absolute;
  cursor: pointer;
  margin-right: 15px;
  margin-top: 6px;
  border: 0.2px solid #414141;
  width: 29px;
  height: 29px;
  padding: 2px;
  top: 0%;
  right: 5%;
}

.show_btn {
  border: 0.2px solid #414141;
  width: 25px;
  height: 25px;
  padding: 2px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: transparent;
  color: #ef7f1a;;
}

#dark #orderbook-history-tab .nav {
  background: #1c2030;
}
#dark #orderbook-history-tab .nav-link {
  color: #4f5966;
}

#dark #orderbook-history-tab .nav-link.active {
  color: #fff;
  background: transparent;
}

#orderbook-history-tab {
  height: 836px;
}
@media only screen and (max-width: 767px) {
  #orderbook-history-tab {
    height: 550px;
  }
}

#comp-buy-form {
  display: block;
  width: 100%;
}
#com-buy-close-form {
  display: none;
}
#comp-sell-form {
  display: block;
  width: 100%;
}
#com-sell-close-form {
  display: none;
}
#bottom-navbar {
  display: none;
}
@media only screen and (max-width: 767px) {
  #comp-buy-form {
    display: none;
  }
  #com-buy-close-form {
    display: block;
  }
  #comp-sell-form {
    display: none;
  }
  #com-sell-close-form {
    display: block;
  }
  #bottom-navbar {
    display: block;
    background-color: #131722;
    padding-top: 15px;
    padding-bottom: 15px;
    border-top: 1px solid #888;
  }
  #bottom-navbar .btn-buy {
    background: #26de81;
    color: #fff;
    text-transform: uppercase;
    padding: 10px;
    width: 100%;
    box-shadow: 0px 0px 15px 0px rgb(38 222 129 / 68%);
  }
  #bottom-navbar .btn-sell {
    background: #ff666d;
    color: #fff;
    text-transform: uppercase;
    padding: 10px;
    width: 100%;
    box-shadow: 0px 0px 15px 0px rgb(255 35 31 / 68%);
  }
}

.red-bg-100,
.red-bg-99,
.red-bg-98,
.red-bg-97,
.red-bg-96,
.red-bg-95,
.red-bg-94,
.red-bg-93,
.red-bg-92,
.red-bg-91,
.red-bg-90,
.red-bg-89,
.red-bg-88,
.red-bg-87,
.red-bg-86,
.red-bg-85,
.red-bg-84,
.red-bg-83,
.red-bg-82,
.red-bg-81,
.red-bg-80,
.red-bg-79,
.red-bg-78,
.red-bg-77,
.red-bg-76,
.red-bg-75,
.red-bg-74,
.red-bg-73,
.red-bg-72,
.red-bg-71,
.red-bg-70,
.red-bg-69,
.red-bg-68,
.red-bg-67,
.red-bg-66,
.red-bg-65,
.red-bg-64,
.red-bg-63,
.red-bg-62,
.red-bg-61,
.red-bg-60,
.red-bg-59,
.red-bg-58,
.red-bg-57,
.red-bg-56,
.red-bg-55,
.red-bg-54,
.red-bg-53,
.red-bg-52,
.red-bg-51,
.red-bg-50,
.red-bg-49,
.red-bg-48,
.red-bg-47,
.red-bg-46,
.red-bg-45,
.red-bg-44,
.red-bg-43,
.red-bg-42,
.red-bg-41,
.red-bg-40,
.red-bg-39,
.red-bg-38,
.red-bg-37,
.red-bg-36,
.red-bg-35,
.red-bg-34,
.red-bg-33,
.red-bg-32,
.red-bg-31,
.red-bg-30,
.red-bg-29,
.red-bg-28,
.red-bg-27,
.red-bg-26,
.red-bg-25,
.red-bg-24,
.red-bg-23,
.red-bg-22,
.red-bg-21,
.red-bg-20,
.red-bg-19,
.red-bg-18,
.red-bg-17,
.red-bg-16,
.red-bg-15,
.red-bg-14,
.red-bg-13,
.red-bg-12,
.red-bg-11,
.red-bg-10,
.red-bg-9,
.red-bg-8,
.red-bg-7,
.red-bg-6,
.red-bg-5,
.red-bg-4,
.red-bg-3,
.red-bg-2,
.red-bg-1,
.red-bg-0,
.red-bg,
.green-bg-100,
.green-bg-99,
.green-bg-98,
.green-bg-97,
.green-bg-96,
.green-bg-95,
.green-bg-94,
.green-bg-93,
.green-bg-92,
.green-bg-91,
.green-bg-90,
.green-bg-89,
.green-bg-88,
.green-bg-87,
.green-bg-86,
.green-bg-85,
.green-bg-84,
.green-bg-83,
.green-bg-82,
.green-bg-81,
.green-bg-80,
.green-bg-79,
.green-bg-78,
.green-bg-77,
.green-bg-76,
.green-bg-75,
.green-bg-74,
.green-bg-73,
.green-bg-72,
.green-bg-71,
.green-bg-70,
.green-bg-69,
.green-bg-68,
.green-bg-67,
.green-bg-66,
.green-bg-65,
.green-bg-64,
.green-bg-63,
.green-bg-62,
.green-bg-61,
.green-bg-60,
.green-bg-59,
.green-bg-58,
.green-bg-57,
.green-bg-56,
.green-bg-55,
.green-bg-54,
.green-bg-53,
.green-bg-52,
.green-bg-51,
.green-bg-50,
.green-bg-49,
.green-bg-48,
.green-bg-47,
.green-bg-46,
.green-bg-45,
.green-bg-44,
.green-bg-43,
.green-bg-42,
.green-bg-41,
.green-bg-40,
.green-bg-39,
.green-bg-38,
.green-bg-37,
.green-bg-36,
.green-bg-35,
.green-bg-34,
.green-bg-33,
.green-bg-32,
.green-bg-31,
.green-bg-30,
.green-bg-29,
.green-bg-28,
.green-bg-27,
.green-bg-26,
.green-bg-25,
.green-bg-24,
.green-bg-23,
.green-bg-22,
.green-bg-21,
.green-bg-20,
.green-bg-19,
.green-bg-18,
.green-bg-17,
.green-bg-16,
.green-bg-15,
.green-bg-14,
.green-bg-13,
.green-bg-12,
.green-bg-11,
.green-bg-10,
.green-bg-9,
.green-bg-8,
.green-bg-7,
.green-bg-6,
.green-bg-5,
.green-bg-4,
.green-bg-3,
.green-bg-2,
.green-bg-1,
.green-bg-0,
.green-bg {
  position: relative;
}
.red-bg-100:after,
.red-bg-99:after,
.red-bg-98:after,
.red-bg-97:after,
.red-bg-96:after,
.red-bg-95:after,
.red-bg-94:after,
.red-bg-93:after,
.red-bg-92:after,
.red-bg-91:after,
.red-bg-90:after,
.red-bg-89:after,
.red-bg-88:after,
.red-bg-87:after,
.red-bg-86:after,
.red-bg-85:after,
.red-bg-84:after,
.red-bg-83:after,
.red-bg-82:after,
.red-bg-81:after,
.red-bg-80:after,
.red-bg-79:after,
.red-bg-78:after,
.red-bg-77:after,
.red-bg-76:after,
.red-bg-75:after,
.red-bg-74:after,
.red-bg-73:after,
.red-bg-72:after,
.red-bg-71:after,
.red-bg-70:after,
.red-bg-69:after,
.red-bg-68:after,
.red-bg-67:after,
.red-bg-66:after,
.red-bg-65:after,
.red-bg-64:after,
.red-bg-63:after,
.red-bg-62:after,
.red-bg-61:after,
.red-bg-60:after,
.red-bg-59:after,
.red-bg-58:after,
.red-bg-57:after,
.red-bg-56:after,
.red-bg-55:after,
.red-bg-54:after,
.red-bg-53:after,
.red-bg-52:after,
.red-bg-51:after,
.red-bg-50:after,
.red-bg-49:after,
.red-bg-48:after,
.red-bg-47:after,
.red-bg-46:after,
.red-bg-45:after,
.red-bg-44:after,
.red-bg-43:after,
.red-bg-42:after,
.red-bg-41:after,
.red-bg-40:after,
.red-bg-39:after,
.red-bg-38:after,
.red-bg-37:after,
.red-bg-36:after,
.red-bg-35:after,
.red-bg-34:after,
.red-bg-33:after,
.red-bg-32:after,
.red-bg-31:after,
.red-bg-30:after,
.red-bg-29:after,
.red-bg-28:after,
.red-bg-27:after,
.red-bg-26:after,
.red-bg-25:after,
.red-bg-24:after,
.red-bg-23:after,
.red-bg-22:after,
.red-bg-21:after,
.red-bg-20:after,
.red-bg-19:after,
.red-bg-18:after,
.red-bg-17:after,
.red-bg-16:after,
.red-bg-15:after,
.red-bg-14:after,
.red-bg-13:after,
.red-bg-12:after,
.red-bg-11:after,
.red-bg-10:after,
.red-bg-9:after,
.red-bg-8:after,
.red-bg-7:after,
.red-bg-6:after,
.red-bg-5:after,
.red-bg-4:after,
.red-bg-3:after,
.red-bg-2:after,
.red-bg-1:after,
.red-bg-0:after,
.red-bg:after {
  position: absolute;
  content: "";
  background: #de262663;
  right: 0;
  top: 0;
  width: 100px;
  height: 45px;
}
.red-bg-100:after {
  width: 200px;
}
.red-bg-99:after {
  width: 198px;
}
.red-bg-98:after {
  width: 196px;
}
.red-bg-97:after {
  width: 194px;
}
.red-bg-96:after {
  width: 192px;
}
.red-bg-95:after {
  width: 190px;
}
.red-bg-94:after {
  width: 188px;
}
.red-bg-93:after {
  width: 186px;
}
.red-bg-92:after {
  width: 184px;
}
.red-bg-91:after {
  width: 182px;
}
.red-bg-90:after {
  width: 180px;
}
.red-bg-89:after {
  width: 178px;
}
.red-bg-88:after {
  width: 176px;
}
.red-bg-87:after {
  width: 174px;
}
.red-bg-86:after {
  width: 172px;
}
.red-bg-85:after {
  width: 170px;
}
.red-bg-84:after {
  width: 168px;
}
.red-bg-83:after {
  width: 166px;
}
.red-bg-82:after {
  width: 164px;
}
.red-bg-81:after {
  width: 162px;
}
.red-bg-80:after {
  width: 160px;
}
.red-bg-79:after {
  width: 158px;
}
.red-bg-78:after {
  width: 156px;
}
.red-bg-77:after {
  width: 154px;
}
.red-bg-76:after {
  width: 152px;
}
.red-bg-75:after {
  width: 150px;
}
.red-bg-74:after {
  width: 148px;
}
.red-bg-73:after {
  width: 146px;
}
.red-bg-72:after {
  width: 144px;
}
.red-bg-71:after {
  width: 142px;
}
.red-bg-70:after {
  width: 140px;
}
.red-bg-69:after {
  width: 138px;
}
.red-bg-68:after {
  width: 136px;
}
.red-bg-67:after {
  width: 134px;
}
.red-bg-66:after {
  width: 132px;
}
.red-bg-65:after {
  width: 130px;
}
.red-bg-64:after {
  width: 128px;
}
.red-bg-63:after {
  width: 126px;
}
.red-bg-62:after {
  width: 124px;
}
.red-bg-61:after {
  width: 122px;
}
.red-bg-60:after {
  width: 120px;
}
.red-bg-59:after {
  width: 118px;
}
.red-bg-58:after {
  width: 116px;
}
.red-bg-57:after {
  width: 114px;
}
.red-bg-56:after {
  width: 112px;
}
.red-bg-55:after {
  width: 110px;
}
.red-bg-54:after {
  width: 108px;
}
.red-bg-53:after {
  width: 106px;
}
.red-bg-52:after {
  width: 104px;
}
.red-bg-51:after {
  width: 102px;
}
.red-bg-50:after {
  width: 100px;
}
.red-bg-49:after {
  width: 98px;
}
.red-bg-48:after {
  width: 96px;
}
.red-bg-47:after {
  width: 94px;
}
.red-bg-46:after {
  width: 92px;
}
.red-bg-45:after {
  width: 90px;
}
.red-bg-44:after {
  width: 88px;
}
.red-bg-43:after {
  width: 86px;
}
.red-bg-42:after {
  width: 84px;
}
.red-bg-41:after {
  width: 82px;
}
.red-bg-40:after {
  width: 80px;
}
.red-bg-39:after {
  width: 78px;
}
.red-bg-38:after {
  width: 76px;
}
.red-bg-37:after {
  width: 74px;
}
.red-bg-36:after {
  width: 72px;
}
.red-bg-35:after {
  width: 70px;
}
.red-bg-34:after {
  width: 68px;
}
.red-bg-33:after {
  width: 66px;
}
.red-bg-32:after {
  width: 64px;
}
.red-bg-31:after {
  width: 62px;
}
.red-bg-30:after {
  width: 60px;
}
.red-bg-29:after {
  width: 58px;
}
.red-bg-28:after {
  width: 56px;
}
.red-bg-27:after {
  width: 54px;
}
.red-bg-26:after {
  width: 52px;
}
.red-bg-25:after {
  width: 50px;
}
.red-bg-24:after {
  width: 48px;
}
.red-bg-23:after {
  width: 46px;
}
.red-bg-22:after {
  width: 44px;
}
.red-bg-21:after {
  width: 42px;
}
.red-bg-20:after {
  width: 40px;
}
.red-bg-19:after {
  width: 38px;
}
.red-bg-18:after {
  width: 36px;
}
.red-bg-17:after {
  width: 34px;
}
.red-bg-16:after {
  width: 32px;
}
.red-bg-15:after {
  width: 30px;
}
.red-bg-14:after {
  width: 28px;
}
.red-bg-13:after {
  width: 26px;
}
.red-bg-12:after {
  width: 24px;
}
.red-bg-11:after {
  width: 22px;
}
.red-bg-10:after {
  width: 20px;
}
.red-bg-9:after {
  width: 18px;
}
.red-bg-8:after {
  width: 16px;
}
.red-bg-7:after {
  width: 14px;
}
.red-bg-6:after {
  width: 12px;
}
.red-bg-5:after {
  width: 10px;
}
.red-bg-4:after {
  width: 8px;
}
.red-bg-3:after {
  width: 6px;
}
.red-bg-2:after {
  width: 4px;
}
.red-bg-1:after {
  width: 2px;
}
.red-bg-0:after {
  width: 0px;
}

.green-bg-100:after,
.green-bg-99:after,
.green-bg-98:after,
.green-bg-97:after,
.green-bg-96:after,
.green-bg-95:after,
.green-bg-94:after,
.green-bg-93:after,
.green-bg-92:after,
.green-bg-91:after,
.green-bg-90:after,
.green-bg-89:after,
.green-bg-88:after,
.green-bg-87:after,
.green-bg-86:after,
.green-bg-85:after,
.green-bg-84:after,
.green-bg-83:after,
.green-bg-82:after,
.green-bg-81:after,
.green-bg-80:after,
.green-bg-79:after,
.green-bg-78:after,
.green-bg-77:after,
.green-bg-76:after,
.green-bg-75:after,
.green-bg-74:after,
.green-bg-73:after,
.green-bg-72:after,
.green-bg-71:after,
.green-bg-70:after,
.green-bg-69:after,
.green-bg-68:after,
.green-bg-67:after,
.green-bg-66:after,
.green-bg-65:after,
.green-bg-64:after,
.green-bg-63:after,
.green-bg-62:after,
.green-bg-61:after,
.green-bg-60:after,
.green-bg-59:after,
.green-bg-58:after,
.green-bg-57:after,
.green-bg-56:after,
.green-bg-55:after,
.green-bg-54:after,
.green-bg-53:after,
.green-bg-52:after,
.green-bg-51:after,
.green-bg-50:after,
.green-bg-49:after,
.green-bg-48:after,
.green-bg-47:after,
.green-bg-46:after,
.green-bg-45:after,
.green-bg-44:after,
.green-bg-43:after,
.green-bg-42:after,
.green-bg-41:after,
.green-bg-40:after,
.green-bg-39:after,
.green-bg-38:after,
.green-bg-37:after,
.green-bg-36:after,
.green-bg-35:after,
.green-bg-34:after,
.green-bg-33:after,
.green-bg-32:after,
.green-bg-31:after,
.green-bg-30:after,
.green-bg-29:after,
.green-bg-28:after,
.green-bg-27:after,
.green-bg-26:after,
.green-bg-25:after,
.green-bg-24:after,
.green-bg-23:after,
.green-bg-22:after,
.green-bg-21:after,
.green-bg-20:after,
.green-bg-19:after,
.green-bg-18:after,
.green-bg-17:after,
.green-bg-16:after,
.green-bg-15:after,
.green-bg-14:after,
.green-bg-13:after,
.green-bg-12:after,
.green-bg-11:after,
.green-bg-10:after,
.green-bg-9:after,
.green-bg-8:after,
.green-bg-7:after,
.green-bg-6:after,
.green-bg-5:after,
.green-bg-4:after,
.green-bg-3:after,
.green-bg-2:after,
.green-bg-1:after,
.green-bg-0:after,
.green-bg:after {
  position: absolute;
  content: "";
  background: #26de8163;
  right: 0;
  top: 0;
  width: 280px;
  height: 45px;
}
.green-bg-100:after {
  width: 200px;
}
.green-bg-99:after {
  width: 198px;
}
.green-bg-98:after {
  width: 196px;
}
.green-bg-97:after {
  width: 194px;
}
.green-bg-96:after {
  width: 192px;
}
.green-bg-95:after {
  width: 190px;
}
.green-bg-94:after {
  width: 188px;
}
.green-bg-93:after {
  width: 186px;
}
.green-bg-92:after {
  width: 184px;
}
.green-bg-91:after {
  width: 182px;
}
.green-bg-90:after {
  width: 180px;
}
.green-bg-89:after {
  width: 178px;
}
.green-bg-88:after {
  width: 176px;
}
.green-bg-87:after {
  width: 174px;
}
.green-bg-86:after {
  width: 172px;
}
.green-bg-85:after {
  width: 170px;
}
.green-bg-84:after {
  width: 168px;
}
.green-bg-83:after {
  width: 166px;
}
.green-bg-82:after {
  width: 164px;
}
.green-bg-81:after {
  width: 162px;
}
.green-bg-80:after {
  width: 160px;
}
.green-bg-79:after {
  width: 158px;
}
.green-bg-78:after {
  width: 156px;
}
.green-bg-77:after {
  width: 154px;
}
.green-bg-76:after {
  width: 152px;
}
.green-bg-75:after {
  width: 150px;
}
.green-bg-74:after {
  width: 148px;
}
.green-bg-73:after {
  width: 146px;
}
.green-bg-72:after {
  width: 144px;
}
.green-bg-71:after {
  width: 142px;
}
.green-bg-70:after {
  width: 140px;
}
.green-bg-69:after {
  width: 138px;
}
.green-bg-68:after {
  width: 136px;
}
.green-bg-67:after {
  width: 134px;
}
.green-bg-66:after {
  width: 132px;
}
.green-bg-65:after {
  width: 130px;
}
.green-bg-64:after {
  width: 128px;
}
.green-bg-63:after {
  width: 126px;
}
.green-bg-62:after {
  width: 124px;
}
.green-bg-61:after {
  width: 122px;
}
.green-bg-60:after {
  width: 120px;
}
.green-bg-59:after {
  width: 118px;
}
.green-bg-58:after {
  width: 116px;
}
.green-bg-57:after {
  width: 114px;
}
.green-bg-56:after {
  width: 112px;
}
.green-bg-55:after {
  width: 110px;
}
.green-bg-54:after {
  width: 108px;
}
.green-bg-53:after {
  width: 106px;
}
.green-bg-52:after {
  width: 104px;
}
.green-bg-51:after {
  width: 102px;
}
.green-bg-50:after {
  width: 100px;
}
.green-bg-49:after {
  width: 98px;
}
.green-bg-48:after {
  width: 96px;
}
.green-bg-47:after {
  width: 94px;
}
.green-bg-46:after {
  width: 92px;
}
.green-bg-45:after {
  width: 90px;
}
.green-bg-44:after {
  width: 88px;
}
.green-bg-43:after {
  width: 86px;
}
.green-bg-42:after {
  width: 84px;
}
.green-bg-41:after {
  width: 82px;
}
.green-bg-40:after {
  width: 80px;
}
.green-bg-39:after {
  width: 78px;
}
.green-bg-38:after {
  width: 76px;
}
.green-bg-37:after {
  width: 74px;
}
.green-bg-36:after {
  width: 72px;
}
.green-bg-35:after {
  width: 70px;
}
.green-bg-34:after {
  width: 68px;
}
.green-bg-33:after {
  width: 66px;
}
.green-bg-32:after {
  width: 64px;
}
.green-bg-31:after {
  width: 62px;
}
.green-bg-30:after {
  width: 60px;
}
.green-bg-29:after {
  width: 58px;
}
.green-bg-28:after {
  width: 56px;
}
.green-bg-27:after {
  width: 54px;
}
.green-bg-26:after {
  width: 52px;
}
.green-bg-25:after {
  width: 50px;
}
.green-bg-24:after {
  width: 48px;
}
.green-bg-23:after {
  width: 46px;
}
.green-bg-22:after {
  width: 44px;
}
.green-bg-21:after {
  width: 42px;
}
.green-bg-20:after {
  width: 40px;
}
.green-bg-19:after {
  width: 38px;
}
.green-bg-18:after {
  width: 36px;
}
.green-bg-17:after {
  width: 34px;
}
.green-bg-16:after {
  width: 32px;
}
.green-bg-15:after {
  width: 30px;
}
.green-bg-14:after {
  width: 28px;
}
.green-bg-13:after {
  width: 26px;
}
.green-bg-12:after {
  width: 24px;
}
.green-bg-11:after {
  width: 22px;
}
.green-bg-10:after {
  width: 20px;
}
.green-bg-9:after {
  width: 18px;
}
.green-bg-8:after {
  width: 16px;
}
.green-bg-7:after {
  width: 14px;
}
.green-bg-6:after {
  width: 12px;
}
.green-bg-5:after {
  width: 10px;
}
.green-bg-4:after {
  width: 8px;
}
.green-bg-3:after {
  width: 6px;
}
.green-bg-2:after {
  width: 4px;
}
.green-bg-1:after {
  width: 2px;
}
.green-bg-0:after {
  width: 0px;
}

tbody tr td {
  word-wrap: break-word;
}
</style>
