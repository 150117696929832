<template>
  <div class="card">
    <div class="card-body">
      <form
        @submit.prevent="
          user.two_fa_is_active ? submitWithdrawal() : toggleModalOtp()
        "
      >
        <div class="mt-4">
          <div v-if="travel_rules_element" class="mb-4">
            <div class="row mt-1">
              <div class="col-md-4 text-right">&nbsp;</div>
              <div class="col-md-8">
                <h4 class="text-dark">Travel Rules</h4>
              </div>
            </div>
            <div class="row mt-1">
              <label class="col-md-4 text-right">Nama Penerima :</label>
              <div class="col-md-8">
                <input type="text" v-model="wd.to_name" class="form-control" />
              </div>
            </div>

            <div class="row mt-1">
              <label class="col-md-4 text-right">Alamat Penerima :</label>
              <div class="col-md-8">
                <input
                  type="text"
                  v-model="wd.to_home_address"
                  class="form-control"
                />
              </div>
            </div>
          </div>

          <div class="row mt-1">
            <label class="col-md-4 text-right">Chain ID :</label>
            <div class="col-md-8">
              <select
                v-model="wd.chain_contract"
                class="form-control"
                @change="findFee"
              >
                <option
                  v-for="item in evm_tokens"
                  :key="item.id"
                  :value="`${item.chain_id}-${item.contract_address}`"
                >
                  {{ item.chain_id }} - {{ item.contract_address }}
                </option>
              </select>
            </div>
          </div>

          <div class="row mt-1">
            <label class="col-md-4 text-right">Wallet Address :</label>
            <div class="col-md-8">
              <input
                type="text"
                v-model="wd.withdraw_to"
                class="form-control"
              />
            </div>
          </div>

          <div class="row mt-1">
            <label class="col-md-4 text-right">Jumlah :</label>
            <div class="col-md-8">
              <input
                type="text"
                v-model="wd.amount"
                class="form-control"
                @keyup="calcAfterFee"
                @keydown="calcAfterFee"
              />
            </div>
          </div>

          <div class="row mt-1">
            <label class="col-md-4 text-right">Biaya :</label>
            <div class="col-md-8">
              <input type="text" v-model="wd.fee" class="form-control" />
            </div>
          </div>

          <div class="row mt-1">
            <label class="col-md-4 text-right">Terima Bersih :</label>
            <div class="col-md-8">
              <input type="text" v-model="after_fee" class="form-control" />
            </div>
          </div>

          <!-- dengan OTP jika 2FA tidak aktif -->
          <!-- <div class="row mt-1" v-if="user.two_fa_is_active == false">
            <label class="col-md-4 text-right">OTP :</label>
            <div class="col-md-8">
              <div class="input-group">
                <input type="text" v-model="wd.otp" class="form-control" />
                <div class="input-group-append">
                  <BtnRequestOtp />
                </div>
              </div>
            </div>
          </div> -->

          <!-- dengan OTP jika 2FA tidak aktif -->
          <div class="row mt-1" v-if="user.two_fa_is_active == true">
            <label class="col-md-4 text-right">Google Secret :</label>
            <div class="col-md-8">
              <input
                type="text"
                v-model="wd.google_secret"
                class="form-control"
              />
            </div>
          </div>

          <div class="row mt-4">
            <div class="col-md-4">&nbsp;</div>
            <div class="col-md-8">
              <button type="submit" class="btn btn-light">Withdraw</button>

              <ModalOtp :show="isModalOtpShow" @getOtpEvent="getOtp" />
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
// node_modules
import Cookies from "js-cookie";
import { useToast } from "vue-toastification";

// applibs
import themain from "@/applibs/themain.js";
import thestore from "@/applibs/thestore.js";

// components
// import BtnRequestOtp from "@/components/global/BtnRequestOtp.vue";
import ModalOtp from "@/components/modals/ModalOtp.vue";

const toast = useToast();

export default {
  components: {
    // BtnRequestOtp,
    ModalOtp,
  },
  props: {
    currency: {
      type: String,
      required: true,
    },
    ccurrencyObj: {
      type: Object,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      themain,
      token: Cookies.get("jwt"),
      usid: Cookies.get("usid"),
      evm_tokens: [],
      wd: {
        amount: 0,
        otp: "",
        google_secret: "",
        withdraw_to: "",
        to_name: "",
        chain_contract: "",
        fee: 0,
        to_name: "",
        to_home_address: "",
      },
      after_fee: 0,
      isModalOtpShow: false,

      travel_rules_element: false,
    };
  },

  async mounted() {
    var self = this;
    // ambil semua network yang di support oleh ini
    await self.getEvmTokenAddress();
  },

  methods: {
    async getEvmTokenAddress() {
      var self = this;
      var res = await thestore.getEvmTokensByCcurrencyId(self.ccurrencyObj.id);
      self.evm_tokens = res;
    },

    async submitWithdrawal() {
      var self = this;

      // karena selectnya tidak kita buat :value, maka kita parsing saja isiannya
      var chain_contract = self.wd.chain_contract.split("-");

      if (parseFloat(self.wd.amount) >= self.ccurrencyObj.travel_rules_limit) {
        if (self.wd.to_name == "" || self.wd.to_home_address == "") {
          alert("Anda wajib mengisi nama dan alamat penerima");
          return;
        }
      }

      var res_a = await thestore.postEvmTokenWithdraw(
        self.token,
        self.wd.otp,
        self.wd.google_secret,
        chain_contract[0],
        chain_contract[1],
        self.currency,
        self.wd.amount,
        self.wd.withdraw_to,
        self.wd.to_name,
        self.wd.to_home_address
      );
      if (res_a.success == true) {
        alert("Success");
        // self.wd.otp = '';
        // self.wd.google_secret = '';
        // self.wd.amount = 0;
        // self.wd.withdraw_to = '';
        self.$router.go();
      } else {
        alert(res_a.msg);
      }
    },

    calcAfterFee() {
      var self = this;
      self.after_fee = parseFloat(self.wd.amount) - parseFloat(self.wd.fee);

      if (parseFloat(self.wd.amount) >= self.ccurrencyObj.travel_rules_limit) {
        self.travel_rules_element = true;
      } else {
        self.travel_rules_element = false;
      }
    },

    findFee() {
      var self = this;

      var chain_contract = self.wd.chain_contract.split("-");

      // cari chain object yang dipilih
      var chainObj = self.evm_tokens.find(
        (u) => parseInt(u.chain_id) === parseInt(chain_contract[0])
      );

      self.wd.fee = chainObj.wd_fee;

      self.calcAfterFee();
    },

    toggleModalOtp() {
      // validasi untuk setiap kolom form harus diisi jika tidak fungsi akan break
      let isValid = this.formValidate();

      if (!isValid) {
        return;
      }

      // toggle untuk modal OTP jika 2FA disable
      if (!this.user.two_fa_is_active)
        this.isModalOtpShow = !this.isModalOtpShow;
    },

    getOtp(code) {
      this.wd.otp = code;
      this.submitWithdrawal();
    },

    formValidate() {
      if (
        !this.wd.chain_contract ||
        !this.wd.withdraw_to ||
        !this.wd.to_name ||
        !this.wd.to_home_address ||
        !this.wd.amount
      ) {
        toast.warning("Kolom tidak boleh kosong");
        return false;
      }

      return true;
    },
  },
};
</script>
