<template>
  <div class="market-trade-buy">
    <div class="row">
      <div class="col pr-1 text-dark">
        Fee: <br />
        <small>
          {{ (parseFloat(marketData.ticker_percent_spread) * 100).toFixed(2) }}
          %
        </small>
      </div>
      <div class="col-8 pl-1 text-dark text-right">
        Tersedia: <br />
        <small
          >{{ parseFloat(buyForm.tokenBOwned).numberFormat(4) }}
          {{ simbol_b }}</small
        >
      </div>
    </div>

    <div class="input-group mt-2">
      <input
        v-if="isFormatNumber == false"
        type="text"
        v-model="buyForm.price"
        class="form-control"
        @blur="formatPrice"
        @keyup="totalCalculation"
        @click="handleClick"
        placeholder="Price"
      />
      <input
        class="form-control"
        v-else
        type="text"
        v-model="idrFormat"
        @click="handleClick"
      />
      <div class="input-group-append">
        <span class="input-group-text">{{ simbol_b }}</span>
      </div>
    </div>
    <div class="input-group">
      <input
        type="text"
        placeholder="Qty"
        class="form-control"
        v-model="buyForm.qtyA"
        @keyup="totalCalculation"
      />

      <div class="input-group-append">
        <span class="input-group-text">{{ simbol_a }}</span>
      </div>
    </div>

    <ul class="market-trade-list">
      <li><span @click="percentClicked(0.25)" class="percent">25%</span></li>
      <li><span @click="percentClicked(0.5)" class="percent">50%</span></li>
      <li><span @click="percentClicked(0.75)" class="percent">75%</span></li>
      <li><span @click="percentClicked(1)" class="percent">100%</span></li>
    </ul>

    <p>
      Total:
      <span>{{ parseFloat(buyForm.qtyB).numberFormat(4) }} {{ simbol_b }}</span>
    </p>

    <div class="row">
      <div class="col-12">
        <button class="btn buy" @click="buyBtnClicked">Beli</button>
      </div>
    </div>
  </div>
</template>

<script>
// node_modules
import Cookies from "js-cookie";

// applibs
import thestore from "@/applibs/thestore.js";
import themain from "@/applibs/themain.js";

export default {
  props: {
    marketData: {
      type: Object,
      required: true,
    },
    currencyAObj: {
      type: Object,
    },
    currencyBObj: {
      type: Object,
    },
    handleFormatDisplay: {
      type: Function,
    },
    isFormatNumber: {
      type: Boolean,
    },
  },
  data() {
    return {
      themain,
      market_id: this.$route.params.market_id,
      market_pair: this.$route.params.market_pair,
      token: Cookies.get("jwt"),
      simbol_a: "",
      simbol_b: "",
      buyForm: {
        price: 0,
        qtyB: 0,
        qtyA: 0,
        tokenBOwned: 0,
      },
      f_sells: [],
      // isFormatNumber: false,
      idrFormat: 0,
    };
  },
  async created() {
    var self = this;

    self.emitter.on("fe-ubalances", async function (e) {
      self.ubalances = e.data;
      self.buyForm.tokenBOwned = e.data[self.simbol_b];
    });

    self.emitter.on("fe-sells", async function (e) {
      self.f_sells = e.data.sort((a, b) => {
        return parseFloat(b.market_position) - parseFloat(a.market_position);
      });
      if (self.f_sells && self.f_sells.length > 0) {
        self.price_sell = self.f_sells[self.f_sells.length - 1].market_position;
      } else {
        self.price_sell = 0;
      }
    });

    self.emitter.on("fe-obook_clicked", async function (e) {
      self.buyForm.price = parseFloat(e.data.obj.market_position);
      if (e.data.kind == "buy") {
        self.buyForm.qtyA = e.data.obj.market_result_including_fee;
      } else {
        var jumlah = 0;
        self.f_sells.forEach((el) => {
          if (
            parseFloat(el.market_position) <=
            parseFloat(e.data.obj.market_position)
          ) {
            jumlah = jumlah + parseFloat(el.market_amount);
          }
        });
        self.buyForm.qtyA = jumlah.toFixed(8);
        self.handleFormatDisplay(false);
        // self.idrFormat = jumlah;
      }
      self.totalCalculation();
    });
  },
  async mounted() {
    var self = this;
    var market_pair_arr = self.market_pair.split("_");
    self.simbol_a = market_pair_arr[0];
    self.simbol_b = market_pair_arr[1];

    var res = await thestore.getBalances(self.token, 1, 10, "id", "asc", [
      { field: "ccurrency_symbol", op: "equal", value: self.simbol_b },
    ]);

    self.buyForm.tokenBOwned = res.datas[0].saldo_value;
  },
  methods: {
    handleClick() {
      this.handleFormatDisplay(false);
    },
    totalCalculation() {
      const hasil =
        parseFloat(this.buyForm.price) * parseFloat(this.buyForm.qtyA);
      this.buyForm.qtyB = hasil;
    },
    formatPrice() {
      this.handleFormatDisplay(true);
      const formattedNumber = new Intl.NumberFormat().format(
        this.buyForm.price
      );
      this.idrFormat = formattedNumber;

      this.buyForm.price = parseFloat(this.buyForm.price);
    },
    formatQtyA() {
      this.buyForm.qtyA = parseFloat(this.buyForm.qtyA);
    },
    percentClicked(percent) {
      if (this.buyForm.price !== "" && parseFloat(this.buyForm.price) > 0) {
        this.buyForm.qtyB = parseFloat(this.buyForm.tokenBOwned) * percent;
        this.buyForm.qtyA =
          parseFloat(this.buyForm.qtyB) / parseFloat(this.buyForm.price);
      } else {
        alert("Please input price first");
      }
    },
    async buyBtnClicked() {
      var self = this;
      console.log(
        self.market_pair,
        "Limit Buy",
        self.buyForm.price,
        self.buyForm.qtyB,
        false
      );

      await thestore.postExchange(
        self.token,
        self.market_pair,
        "Limit Buy",
        self.buyForm.price,
        self.buyForm.qtyB,
        false
      );
      self.handleFormatDisplay(false);
    },
  },
};
</script>

<style scoped>
/* Your existing styles */
#dark .market-trade-list li span.percent {
  background: #2a2e39;
  color: #ffffff;
}
.market-trade-list li span.percent {
  background: #eff2f6;
  color: #4a4a4a;
  font-weight: 300;
  padding: 3px 15px;
  border-radius: 5px;
  cursor: pointer;
}
.market-trade-list {
  margin-bottom: 0px;
}
.market-trade button.buy {
  margin-top: 4px;
}
.form-control {
  color: #000 !important;
}
</style>
