<template>
  <div>
    <TopNav />
    <div class="tnc_head">
      <span>Kebijakan Privasi</span>
    </div>
    <div class="container">
      <PrivacyPolicy />
    </div>

    <Footer />
  </div>
</template>

<script>
// node_modules

// applibs

import TopNav from "@/components/global/TopNav.vue";
import Footer from "@/components/global/Footer.vue";
import PrivacyPolicy from "@/components/global/PrivacyPolicy.vue";

export default {
  components: {
    TopNav,
    Footer,
    PrivacyPolicy,
  },
  data() {
    return {
      web_domain: "coinx.co.id",
    };
  },
};
</script>

<style scoped>
.container {
  display: flex;
  gap: 30px;
}
h2 {
  font-size: 2.5rem;
}
h5.title {
  margin-top: 3rem;
  font-size: 2rem;
}

.tnc_head {
  width: 100%;
  height: 100px;
  background-color: #F7931A;

  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;
  margin-top: 20px;
}

.tnc_head span {
  font-size: 20px;
  font-weight: 600;
  color: #f0f3f7;
}

.tnc_menu {
  position: -webkit-sticky;
  position: fixed !important;
  top: 25%;
  left: 15%;
  display: flex;
  flex-direction: column;
}

.menu_i {
  padding: 3px 10px;
  border: 1px solid #e0e0e0;
  color: #f0f3f7;
}

.menu_i:hover {
  background-color: #9c9c9c;
}

.none_t {
  width: 150px;
}

@media only screen and (max-width: 1527px) {
  .tnc_menu {
    left: 5%;
  }
}

@media only screen and (max-width: 1171px) {
  .tnc_menu,
  .none_t {
    display: none;
  }
}
</style>
