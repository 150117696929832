<!--
  halaman ini hanya bisa diakses ketika status kyc approve
  dan agreement masih false
-->
<template>
  <div class="st_p-wrapper">
    <TopNav />

    <div class="settings mtb15">
      <div class="container-fluid">
        <div class="row">
          <Menu />

          <div class="col-md-12 col-lg-9">
            <div class="row">
              <div class="col-lg-12">
                <div class="alert alert-info">
                  Kartu identitas dan video terverifikasi benar. <br />
                  Silahkan lengkapi data Anda untuk melanjutkan proses KYC.
                </div>
              </div>
            </div>

            <div class="form-row mt-4">
              <div class="col-md-12 mb-2">
                <label> Nama Lengkap: </label>
                <input
                  type="text"
                  v-model="user.orang_name"
                  class="form-control"
                />
              </div>

              <div class="col-md-12 mb-2">
                <label> Nomor Identitas: </label>
                <input
                  type="text"
                  v-model="user.orang_nik"
                  class="form-control"
                />
              </div>

              <div class="col-md-12 mb-2">
                <label> Pekerjaan: </label>
                <input
                  type="text"
                  v-model="user.orang_profession"
                  class="form-control"
                />
              </div>

              <div class="col-md-12 mb-2">
                <label> Alamat Sesuai Identitas: </label>
                <input
                  type="text"
                  v-model="user.orang_address"
                  class="form-control"
                />
              </div>

              <div class="col-md-12 mb-2">
                <label> Domisili saat ini: </label>
                <input
                  type="text"
                  v-model="user.orang_domicile"
                  class="form-control"
                />
              </div>

              <div class="col-md-12 mb-2">
                <label> Sumber Penghasilan: </label>
                <input
                  type="text"
                  v-model="user.orang_income_source"
                  class="form-control"
                />
              </div>

              <div class="col-md-12 mb-2">
                <label> Penghasilan Per-Tahun: </label>
                <input
                  type="text"
                  v-model="user.orang_income"
                  class="form-control"
                />
              </div>

              <div class="col-md-12 mb-2">
                <label> Maksud Pembukaan Akun: </label>
                <input
                  type="text"
                  v-model="user.orang_acc_purpose"
                  class="form-control"
                />
              </div>

              <div class="col-md-12 mb-4">
                <button @click="submitForm" class="btn btn-primary">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
// node_modules
import Cookies from "js-cookie";

// applibs
import themain from "@/applibs/themain.js";
import thestore from "@/applibs/thestore.js";

// components
import TopNav from "@/components/global/TopNav.vue";
import Footer from "@/components/global/Footer.vue";
import Menu from "@/components/Settings/Menu.vue";

export default {
  components: {
    TopNav,
    Footer,
    Menu,
  },
  data() {
    return {
      themain,
      token: Cookies.get("jwt"),
      usid: Cookies.get("usid"),
      user: {
        orang_nik: "",
        orang_name: "",
        orang_profession: "",
        orang_address: "",
        orang_domicile: "",
        orang_income_source: "",
        orang_income: "",
        orang_acc_purpose: "",
      },
    };
  },

  async beforeMount() {
    var self = this;
    // cek expire token
    if (self.token) {
      var res = await thestore.postCheckToken(self.token);
      if (res.success == false) {
        Cookies.remove("jwt");
        Cookies.remove("usid");
        self.$router.go();
      }
    }
  },

  async mounted() {
    var self = this;

    // ambil data dia ketika login
    if (self.token != null && self.token != "") {
      var res = await thestore.getMyProfileData(self.token);
      self.user = res;

      // cegah user ini mengakses jika belum di approve kyc
      if (res.orang_kyc_status == "approve" && res.orang_agreement == false) {
        themain.openModal("info-modal");
      } else {
        // console.log('satu agree');
        // self.$router.push("/");
      }
    } else {
      // console.log('dua agree');
      // self.$router.push("/");
    }
  },

  methods: {
    async submitForm() {
      var self = this;
      var res = await thestore.postUserDataUpdate(self.token, {
        orang_nik: self.user.orang_nik,
        orang_name: self.user.orang_name,
        orang_profession: self.user.orang_profession,
        orang_address: self.user.orang_address,
        orang_domicile: self.user.orang_domicile,
        orang_income_source: self.user.orang_income_source,
        orang_income: self.user.orang_income,
        orang_acc_purpose: self.user.orang_acc_purpose,
      });

      // console.log("res agreement", res);

      if (res.success == true) {
        self.$router.push("/settings/agreement-sign-two");
      } else {
        alert(res.msg);
      }
    },
  },
};
</script>

<style scoped>
.btn-close {
  background: none;
  border: none;
}
</style>
