<template>
  <div class="features_container">
    <div class="features_head">
      <div class="features_mk">Markets</div>
      <h2>Fitur-fitur terdepan dalam perdagangan untuk analisis tingkat lanjut</h2>
      <p>
        Pengguna dapat membuat analisa dengan chart tingkat lanjut sehingga membuat 
        pengalaman perdagangan lebih nyaman
      </p>
    </div>
  </div>
</template>

<style scoped>
.features_container {
  display: flex;
  justify-content: center;
  padding: 0 10px;
}
.features_head {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
.features_mk {
  width: fit-content;
  display: flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background: #ffe4cc;

  font-size: 20px;
  font-weight: 500;
  color: #ef7f1a;
}
.features_head h2 {
  font-size: 36px;
  font-style: normal;
  text-align: center;
  color: #ef7f1a;
  font-weight: 600;
}
.features_head p {
  color: #667085;

  text-align: center;
  font-family: Nunito;
  font-size: 20px;
  font-style: normal;
  text-align: center;
  font-weight: 400;
  line-height: 30px; /* 150% */
  max-width: 768px;
}

@media only screen and (max-width: 600px) {
  .features_head h2,
  .features_mk {
    font-size: 16px;
  }
  .features_head p {
    font-size: 14px;
  }
}
</style>
