<template>
  <div>
    <TopNav />
    <div class="tnc_head">
      <span>Hubungi Kami</span>
    </div>
    <div class="contact-container">
      <div class="form-container text-white">
        <form @submit.prevent="submitForm" style="margin-bottom: 100px">
          <div class="form-group">
            <label for="name">Nama:</label>
            <input
              type="text"
              id="name"
              v-model="form.name"
              class="form-control"
              required
            />
          </div>
          <div class="form-group">
            <label for="email">Email:</label>
            <input
              type="email"
              id="email"
              v-model="form.email"
              class="form-control"
              required
            />
          </div>
          <div class="form-group">
            <label for="subject">Subject:</label>
            <input
              type="text"
              id="subject"
              v-model="form.subject"
              class="form-control"
              required
            />
          </div>
          <div class="form-group">
            <label for="message">Pengaduan:</label>
            <textarea
              id="message"
              v-model="form.message"
              class="form-control"
              required
            ></textarea>
          </div>
          <button type="submit" class="btn btn-primary">Submit</button>
        </form>
      </div>
      <div class="contact-info">
        <h3>Hotline:</h3>
        <p><i class="fas fa-phone"></i> 081398798166</p>
        <h3>Email:</h3>
        <p>
          <i class="fas fa-envelope"></i>
          <a class="email_p" href="mailto:info@astal.co.id">info@astal.co.id</a>
        </p>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import { useToast } from "vue-toastification";

import thestore from "@/applibs/thestore.js";
import TopNav from "@/components/global/TopNav.vue";
import Footer from "@/components/global/Footer.vue";

const toast = useToast();

export default {
  components: {
    TopNav,
    Footer,
  },
  data() {
    return {
      web_domain: "astal.co.id",
      form: {
        name: "",
        subject: "",
        email: "",
        message: "",
      },
    };
  },
  methods: {
    async submitForm() {
      var self = this;
      var res = await thestore.postContactUs({
        name: self.form.name,
        email: self.form.email,
        subject: self.form.subject,
        message: self.form.message,
      });

      if (res.success == true) {
        toast.success("Terimakasih telah menghubugi kami");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        toast.success("Terimakasih telah menghubugi kami");
      }
    },
  },
};
</script>

<style scoped>
.contact-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
  padding-bottom: 50px;
}
.contact-info {
  flex: 1 1 300px;
  background-color: #ffff;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  color: #f0f3f7;
  padding: 20px;
  border-radius: 8px;
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.contact-info h3 {
  margin-bottom: 10px;
  color: #282829;
}
.contact-info p {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 1.2rem;
  color: #282829;
}
.email_p {
  color: #282829 !important;
}
.form-container {
  flex: 2 1 600px;
  background-color: #ffff;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  padding: 20px;
  border-radius: 8px;
}
h2 {
  font-size: 2.5rem;
}
h5.title {
  margin-top: 3rem;
  font-size: 2rem;
}
.form-group {
  margin-bottom: 15px;
}
.form-control {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.btn {
  background-color: #ef7f1a;
  color: #fff;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}
.btn-primary:hover {
  background-color: #ca6812;
}
.tnc_head {
  width: 100%;
  height: 100px;
  background-color: #f7931a;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;
  margin-top: 20px;
}
.tnc_head span {
  font-size: 20px;
  font-weight: 600;
  color: #f0f3f7;
}
@media only screen and (max-width: 768px) {
  .contact-container {
    flex-direction: column;
    align-items: center;
  }
  .contact-info,
  .form-container {
    width: 100%;
  }
}
</style>
