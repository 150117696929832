<template>
  <div class="col-md-12 text-dark">
    <!-- hanya muncul pada big screen -->
    <div class="sum-big-screen">
      <div class="row">
        <div class="col-md-2">
          <div class="fs15">
            <img
              :src="currencyAObj.ccurrency_icon_data_url"
              style="border-radius: 100%; height: 1.8rem; margin-right: 2px"
            />
            {{ simbol_a }}/{{ simbol_b }}
          </div>
        </div>

        <div class="col-md-2">
          <div :class="`${last_price_color} fs13`">
            <span class="display_num">
              {{
                parseFloat(market.last_price).toLocaleString("en-US", {
                  minimumFractionDigits: floatDigit,
                })
              }}
            </span>
            <small>{{ simbol_b }}</small>
          </div>
          <div class="fs08">Harga Terakhir</div>
        </div>

        <div class="col-md-2">
          <div :class="`${price_color} fs10`">
            <span class="display_num">
              {{ parseFloat(market.change_24h).toFixed(floatDigit) }} %
            </span>
          </div>
          <div class="fs08">Perubahan 24j</div>
        </div>

        <div class="col-md-2">
          <div class="fs10">
            <span class="display_num">
              {{
                parseFloat(market.price_buy).toLocaleString("en-US", {
                  minimumFractionDigits: floatDigit,
                })
              }}
            </span>
          </div>
          <div class="fs08 green">Beli</div>
        </div>

        <div class="col-md-2">
          <div class="fs10">
            <span class="display_num">
              {{
                parseFloat(market.price_sell).toLocaleString("en-US", {
                  minimumFractionDigits: floatDigit,
                })
              }}
            </span>
          </div>
          <div class="fs08 red">Jual</div>
        </div>

        <div class="col-md-2">
          <div class="fs10">
            <span class="display_num">
              {{
                parseFloat(market.vol_24h).toLocaleString("en-US", {
                  minimumFractionDigits: floatDigit,
                })
              }}
            </span>
          </div>
          <div class="fs08">Volume 24j</div>
        </div>
      </div>
    </div>

    <!-- hanya muncul pada small screen -->
    <div class="sum-small-screen">
      <div class="row">
        <div class="col-5">
          <div class="fs14 judul" @click="showMarket">
            <img
              :src="currencyAObj.ccurrency_icon_data_url"
              style="border-radius: 100%; height: 1.8rem; margin-right: 2px"
            />
            {{ simbol_a }}/{{ simbol_b }}
          </div>
          <div :class="`${last_price_color} fs13`">
            <span class="display_num">
              {{
                parseFloat(market.last_price).numberFormat(
                  currencyBObj.decimal_display
                )
              }}
            </span>
          </div>
        </div>

        <div class="col-7">
          <div class="row">
            <div class="col">
              <div class="fs10">
                {{
                  parseFloat(market.price_buy).numberFormat(
                    currencyBObj.decimal_display
                  )
                }}
              </div>
              <div class="fs07 green">Buy</div>

              <div class="fs10 mt10">
                {{
                  parseFloat(market.price_sell).numberFormat(
                    currencyBObj.decimal_display
                  )
                }}
              </div>
              <div class="fs07 red">Sell</div>
            </div>

            <div class="col">
              <div class="fs10">
                {{
                  parseFloat(market.vol_24h).numberFormat(
                    currencyBObj.decimal_display
                  )
                }}
              </div>
              <div class="fs07">24h Volume</div>

              <div :class="`${price_color} fs10 mt10`">
                {{ parseFloat(market.change_24h).numberFormat(2) }} %
              </div>
              <div class="fs07">24h Change</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// node_modules
import { mapState } from "vuex";

// applibs
import themain from "@/applibs/themain.js";
// import thestore from "@/applibs/thestore.js";

// components

export default {
  components: {},
  props: {
    marketData: {
      type: Object,
    },
    currencyAObj: {
      type: Object,
    },
    currencyBObj: {
      type: Object,
    },
  },

  data() {
    return {
      themain,

      market_id: this.$route.params.market_id,
      market_pair: this.$route.params.market_pair,

      simbol_a: "",
      simbol_b: "",

      market: {
        last_price: 0,
        price_buy: 0,
        price_sell: 0,
        vol_24h: 0,
        change_24h: 0,
        high_24h: 0,
        low_24h: 0,
        currencies: [{ ccurrency_icon_data_url: "" }],
      },

      price_color: "green",
      last_price_color: "green",

      last_price_before: 0,
    };
  },

  async created() {
    var self = this;

    self.emitter.on("fe-market_update", async function (e) {
      if (e.pair == self.market_pair) {
        self.market.last_price = e.data.last_price;
        self.market.price_buy = e.data.last_bid;
        self.market.price_sell = e.data.last_ask;
        self.market.change_24h = e.data.change_24h;
        self.market.high_24h = e.data.high_24h;
        self.market.low_24h = e.data.low_24h;
        self.market.vol_24h = e.data.vol_24h;

        if (e.data.change_24h >= 0) {
          self.price_color = "green";
        } else {
          self.price_color = "red";
        }

        // setelah mendapat last price yang baru, maka kita buat perhitungan
        self.lastPriceColorCalculation(e.data.last_price);
      }
    });
  },

  computed: {
    ...mapState(["valueSelected", "floatDigit"]),
  },

  async mounted() {
    var self = this;

    // karena kita ingin mengganti data, maka market prop dirubah lagi menjadi market saja
    self.market = self.marketData;
    self.market["currencies"] = [{ ccurrency_icon_data_url: "" }];

    // console.log("marketData", self.marketData);

    var simbols = self.market_pair.split("_");
    self.simbol_a = simbols[0];
    self.simbol_b = simbols[1];
  },

  methods: {
    showMarket() {
      var self = this;
      self.emitter.emit("fe-show-hide-market-for-small-screen", {
        data: "clicked",
      });
    },

    lastPriceColorCalculation(new_price) {
      var self = this;

      // setelah mendapat last price yang baru, maka kita buat perhitungan
      if (parseFloat(new_price) > parseFloat(self.last_price_before)) {
        self.last_price_color = "green";
      } else {
        self.last_price_color = "red";
      }
      self.last_price_before = new_price;
    },
  },
};
</script>

<style scoped>
.fs07 {
  font-size: 0.7rem;
}
.fs08 {
  font-size: 0.8rem;
}
.fs15 {
  font-size: 1.5rem;
}
.fs14 {
  font-size: 1.4rem;
}
.fs13 {
  font-size: 1.3rem;
}
.fs10 {
  font-size: 1rem;
}

.mt10 {
  margin-top: 10px;
}

.sum-big-screen {
  display: block;
  width: 100%;
  padding: 10px 15px;
  word-wrap: break-word;
}
.sum-small-screen {
  display: none;
}
.display_num {
  font-size: 16px;
}
@media only screen and (max-width: 767px) {
  .sum-big-screen {
    display: none;
  }
  .sum-small-screen {
    display: block;
    width: 100%;
    padding: 15px;
    word-wrap: break-word;
  }
  .fs10 {
    font-size: 14px !important;
  }
}

.judul::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  font-size: 0.8rem;
}
</style>
