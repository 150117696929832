// node_modules
import axios from "axios";

// applibs
import themain from "@/applibs/themain.js";

var utama = {
  async postCheckToken(token) {
    var res = await axios({
      method: "post",
      headers: {
        JWT: token,
      },
      url: `${themain.baseApi}/users/check-token`,
    });
    return res.data;
  },

  /**
   * Mendapatkan data pribadi
   * @param {*} token
   * @returns `{"id":3,"orang_address":null,"orang_available_for_deposit":true,
   * "orang_available_for_trading":true,"orang_available_for_withdraw":true,
   * "orang_bank_account_holder":null,"orang_bank_account_number":null,
   * "orang_bank_name":null,"orang_birthday":null,"orang_birthplace":null,
   * "orang_email":"test01@email.com","orang_email_verified":true,"orang_gender":null,
   * "orang_lang":null,"orang_mobilenumber":null,"orang_mobilenumber_verified":null,
   * "orang_name":"test01@email.com","orang_nickname":"028afad6e76f56c1e4a8",
   * "orang_nik":null,"orang_nikfile_data":null,"orang_npwp_file_data":null,
   * "orang_npwp_number":null,"orang_photo_data":null,"orang_photoself_data":null,
   * "orang_profession":null,"orang_sound":true,"orang_status":"active",
   * "orang_timezone":"Asia/Jakarta","orang_kyc_status":"approve","orang_kyc_edit_available":true,
   * "orang_kyc_ask_for_review":false,"orang_warganegara":null,"orang_withdrawlimit":100000000,
   * "two_fa_is_active":false,"orang_niktype":null,"orang_bank_code":null,"orang_regency_code":null,
   * "orang_amb_code":null,"orang_address_province":null,"orang_address_city":null,
   * "orang_video_file_data":null,"orang_as_company":false,"orang_avatar_file_data":null,
   * "orang_photo_liveness_file_data":null,"orang_kyc_nationality":"id","orang_mother_name":null,
   * "created_at":"2023-02-18T22:27:35.832Z","updated_at":"2023-02-19T07:58:49.992Z"}`
   */
  async getMyProfileData(token) {
    var res = await axios({
      method: "get",
      headers: {
        JWT: token,
      },
      url: `${themain.baseApi}/users/mydata`,
    });
    return res.data;
  },

  /**
   *
   * @param {*} token
   * @param {*} user_obj
   * @returns '{"id":3,"orang_address":null,
   * "orang_available_for_deposit":true,"orang_available_for_trading":true,"orang_available_for_withdraw":true,"orang_bank_account_holder":null,"orang_bank_account_number":null,"orang_bank_name":null,"orang_birthday":null,"orang_birthplace":null,"orang_email":"test01@email.com","orang_email_verified":true,"orang_gender":null,"orang_lang":null,"orang_mobilenumber":null,"orang_mobilenumber_verified":null,"orang_name":"test01@email.com","orang_nickname":"028afad6e76f56c1e4a8","orang_nik":null,"orang_nikfile_data":null,"orang_npwp_file_data":null,"orang_npwp_number":null,"orang_photo_data":null,"orang_photoself_data":null,"orang_profession":null,"orang_sound":true,"orang_status":"active","orang_timezone":"Asia/Jakarta","orang_kyc_status":"approve","orang_kyc_edit_available":true,"orang_kyc_ask_for_review":false,"orang_warganegara":null,"orang_withdrawlimit":100000000,"two_fa_is_active":false,"orang_niktype":null,"orang_bank_code":null,"orang_regency_code":null,"orang_amb_code":null,"orang_address_province":null,"orang_address_city":null,"orang_video_file_data":null,"orang_as_company":false,"orang_avatar_file_data":null,"orang_photo_liveness_file_data":null,"orang_kyc_nationality":"id","orang_mother_name":null,"created_at":"2023-02-18T22:27:35.832Z","updated_at":"2023-02-19T07:58:49.992Z"}'
   */
  async postMyProfileData(token, user_obj) {
    var res = await axios({
      method: "post",
      headers: {
        JWT: token,
      },
      data: user_obj,
      url: `${themain.baseApi}/users/mydata`,
    });
    return res.data;
  },

  async postDeleteAccount(token, reason) {
    var res = await axios({
      method: "post",
      headers: {
        JWT: token,
      },
      data: {
        reason,
      },
      url: `${themain.baseApi}/users/delete-account`,
    });
    return res.data;
  },

  async postTwoFaActivate(token, google_secret) {
    var res = await axios({
      method: "post",
      headers: {
        JWT: token,
      },
      data: {
        google_secret,
      },
      url: `${themain.baseApi}/users/two-fa-activate`,
    });
    return res.data;
  },

  async postTwoFaDeactivate(token, google_secret) {
    var res = await axios({
      method: "post",
      headers: {
        JWT: token,
      },
      data: {
        google_secret,
      },
      url: `${themain.baseApi}/users/two-fa-deactivate`,
    });
    return res.data;
  },

  async getMyBank(token) {
    var res = await axios({
      method: "get",
      headers: {
        JWT: token,
      },
      url: `${themain.baseApi}/users/mybank`,
    });
    return res.data;
  },

  async postMyBank(token, name, ac_name, ac_number) {
    var res = await axios({
      method: "post",
      headers: {
        JWT: token,
      },
      url: `${themain.baseApi}/users/mybank`,
      data: {
        name,
        ac_name,
        ac_number,
      },
    });
    return res.data;
  },

  async postKycCheckingStep(token) {
    var res = await axios({
      method: "post",
      headers: {
        JWT: token,
      },
      url: `${themain.baseApi}/kyc/checking-step`,
    });
    return res.data;
  },

  async postKycNationality(token, id_or_global) {
    var res = await axios({
      method: "post",
      headers: {
        JWT: token,
      },
      url: `${themain.baseApi}/kyc/nationality`,
      data: {
        warganegara: id_or_global,
      },
    });
    return res.data;
  },

  async postKycIdPersetujuan(token) {
    var res = await axios({
      method: "post",
      headers: {
        JWT: token,
      },
      url: `${themain.baseApi}/kyc/id/01-persetujuan`,
    });
    return res.data;
  },

  async postKycIdBasicInfo(token, formData) {
    var res = await axios({
      method: "post",
      headers: {
        JWT: token,
        "Content-Type": "multipart/form-data",
      },
      url: `${themain.baseApi}/kyc/id/02-basic-info`,
      data: formData,
    });
    return res.data;
  },

  async postKycIdVideoUpload(token, formData) {
    var res = await axios({
      method: "post",
      headers: {
        JWT: token,
        "Content-Type": "multipart/form-data",
      },
      url: `${themain.baseApi}/kyc/id/03-video-upload`,
      data: formData,
    });
    return res.data;
  },

  async postKycGlobalPersetujuan(token) {
    var res = await axios({
      method: "post",
      headers: {
        JWT: token,
      },
      url: `${themain.baseApi}/kyc/global/01-persetujuan`,
    });
    return res.data;
  },

  async postKycGlobalBasicInfo(token, formData) {
    var res = await axios({
      method: "post",
      headers: {
        JWT: token,
        "Content-Type": "multipart/form-data",
      },
      url: `${themain.baseApi}/kyc/global/02-basic-info`,
      data: formData,
    });
    return res.data;
  },

  async postKycGlobalVideoUpload(token, formData) {
    var res = await axios({
      method: "post",
      headers: {
        JWT: token,
        "Content-Type": "multipart/form-data",
      },
      url: `${themain.baseApi}/kyc/global/03-video-upload`,
      data: formData,
    });
    return res.data;
  },

  async getMarkets(page, rows, sort, order, filter_rules) {
    var res = await axios({
      method: "get",
      url: `${
        themain.baseApi
      }/markets?page=${page}&rows=${rows}&sort=${sort}&order=${order}&filter_rules=${JSON.stringify(
        filter_rules
      )}`,
    });
    return res.data.data;
  },

  async getMarket(pair) {
    var res = await axios({
      method: "get",
      url: `${themain.baseApi}/markets/${pair}`,
    });
    return res.data.data;
  },

  async getCurrencies(page, rows, sort, order, filter_rules) {
    var res = await axios({
      method: "get",
      url: `${
        themain.baseApi
      }/currencies?page=${page}&rows=${rows}&sort=${sort}&order=${order}&filter_rules=${JSON.stringify(
        filter_rules
      )}`,
    });
    return res.data.data;
  },

  async getArticles(page, rows, sort, order, filter_rules) {
    var res = await axios({
      method: "get",
      url: `${
        themain.baseApi
      }/articles?page=${page}&rows=${rows}&sort=${sort}&order=${order}&filter_rules=${JSON.stringify(
        filter_rules
      )}`,
    });
    return res.data.data;
  },

  async postRegister(name, email, password, password_confirm, recap_token) {
    var res = await axios({
      method: "post",
      url: `${themain.baseApi}/auth/signup_post`,
      data: {
        name,
        email,
        password,
        password_confirm,
        "g-recaptcha-response": recap_token,
      },
    });
    return res.data;
  },

  async postAuthLogin(email, password, recap_token) {
    var res = await axios({
      method: "post",
      url: `${themain.baseApi}/auth/signin_post`,
      data: {
        email,
        password,
        "g-recaptcha-response": recap_token,
      },
    });
    return res.data;
  },

  async postAuthLoginTwoFa(email, password, google_secret) {
    var res = await axios({
      method: "post",
      url: `${themain.baseApi}/auth/two-fa-login`,
      data: {
        email,
        password,
        google_secret,
      },
    });
    return res.data;
  },

  async postAuthLogout(token) {
    var res = await axios({
      method: "post",
      headers: {
        JWT: token,
      },
      url: `${themain.baseApi}/auth/signout`,
    });
    return res.data;
  },

  async postAuthEmailVerification(thecode) {
    var res = await axios({
      method: "post",
      url: `${themain.baseApi}/auth/email_verification`,
      data: {
        code: thecode,
      },
    });
    return res.data;
  },

  async postAuthForgotPassword(email, recap_token) {
    var res = await axios({
      method: "post",
      url: `${themain.baseApi}/auth/passchange-request`,
      data: {
        email,
        "g-recaptcha-response": recap_token,
      },
    });
    return res.data;
  },

  async postAuthChangePassword(
    email,
    token,
    password,
    password_confirm,
    recap_token
  ) {
    var res = await axios({
      method: "post",
      url: `${themain.baseApi}/auth/passchange_form_post`,
      data: {
        email,
        token,
        password,
        password_confirm,
        "g-recaptcha-response": recap_token,
      },
    });
    return res.data;
  },

  async postAuthEmailVerificationRequest(email, recap_token) {
    var res = await axios({
      method: "post",
      url: `${themain.baseApi}/auth/email_verification_token_request`,
      data: {
        email,
        "g-recaptcha-response": recap_token,
      },
    });
    return res.data;
  },

  /**
   * Digunakan untuk mengambil balance user yang bersangkutan.
   * @param {*} token
   * @param {*} page
   * @param {*} rows
   * @param {*} sort
   * @param {*} order
   * @param {*} filter_rules
   * @returns
   */
  async getBalances(token, page, rows, sort, order, filter_rules) {
    var res = await axios({
      method: "get",
      headers: {
        JWT: token,
      },
      url: `${
        themain.baseApi
      }/balances?page=${page}&rows=${rows}&sort=${sort}&order=${order}&filter_rules=${JSON.stringify(
        filter_rules
      )}`,
    });
    return res.data.data;
  },

  async postExchange(
    token,
    pair,
    market_place,
    market_position,
    market_amount,
    market_amount_maxbalance_marking
  ) {
    var res = await axios({
      method: "post",
      headers: {
        JWT: token,
      },
      url: `${themain.baseApi}/exchange/${pair}`,
      data: {
        market_place,
        market_position,
        market_amount,
        market_amount_maxbalance_marking,
      },
    });
    return res.data.data;
  },

  // perintahkan api emit orderbook
  async getAndEmitOrderbook(pair) {
    await axios({
      method: "get",
      url: `${themain.baseApi}/obooks/emit/${pair}`,
    });
  },

  /**
   * Digunakan untuk membatalkan order
   * @param {*} id
   */
  async postCancelOrder(token, id) {
    var res = await axios({
      method: "post",
      headers: {
        JWT: token,
      },
      url: `${themain.baseApi}/obooks/cancel`,
      data: {
        id,
      },
    });
    return res.data;
  },

  async getMyOrderBooksOpen(token, page, rows, sort, order, filter_rules) {
    var res = await axios({
      method: "get",
      headers: {
        JWT: token,
      },
      url: `${
        themain.baseApi
      }/obooks/myobook-open?page=${page}&rows=${rows}&sort=${sort}&order=${order}&filter_rules=${JSON.stringify(
        filter_rules
      )}`,
    });
    return res.data.data;
  },

  async getMyOrderBooksClose(token, page, rows, sort, order, filter_rules) {
    var res = await axios({
      method: "get",
      headers: {
        JWT: token,
      },
      url: `${
        themain.baseApi
      }/obooks/myobook-close?page=${page}&rows=${rows}&sort=${sort}&order=${order}&filter_rules=${JSON.stringify(
        filter_rules
      )}`,
    });
    return res.data.data;
  },

  async getCompletedOrderDetail(pair, orderbook_id) {
    var res = await axios({
      method: "get",
      url: `${themain.baseSync}/histories/detail-for-one-orderbook?pair=${pair}&orderbook_id=${orderbook_id}`,
    });
    return res.data;
  },

  /**
   * digunakan untuk memerintahkan aplikasi api mengirimkan ws data dan di load dalam app fe
   * @param {*} pair
   */
  async getHistoriesFirstTime(pair) {
    var res = await axios({
      method: "get",
      url: `${themain.baseSync}/histories/load-first-time?pair=${pair}`,
    });
    return res.data;
  },

  async getDataForExchangePageFirstTime(pair) {
    var res = await axios({
      method: "get",
      url: `${themain.baseSync}/exchange/load-first-time?pair=${pair}`,
    });
    return res.data;
  },

  /**
   * Digunakan untuk mendapatkan address Ethereum saja
   * @param {*} token
   * @returns
   */
  async getEthereumWalletAddress(token) {
    var res = await axios({
      method: "get",
      headers: {
        JWT: token,
      },
      url: `${themain.baseSync}/wallets/ethereum-address`,
    });
    return res.data;
  },

  /**
   * Digunakan untuk membuat address Ethereum saja
   * @param {*} token
   * @returns
   */
  async postRequestEthereumAddress(token) {
    var res = await axios({
      method: "post",
      headers: {
        JWT: token,
      },
      url: `${themain.baseSync}/wallets/get-ethereum-address`,
    });
    return res.data;
  },

  /**
   * Digunakan untuk mendapatkan address Bitcoin saja
   * @param {*} token
   * @returns
   */
  async getBitcoinWalletAddress(token) {
    var res = await axios({
      method: "get",
      headers: {
        JWT: token,
      },
      url: `${themain.baseSync}/wallets/bitcoin-address`,
    });
    return res.data;
  },

  /**
   * Digunakan untuk membuat address Bitcoin saja
   * @param {*} token
   * @returns
   */
  async postRequestBitcoinAddress(token) {
    var res = await axios({
      method: "post",
      headers: {
        JWT: token,
      },
      url: `${themain.baseSync}/wallets/get-bitcoin-address`,
    });
    return res.data;
  },

  /**
   * Digunakan untuk mengambil data deposit dari user
   * Deposit ini dipanggil dari aplikasi kedua.
   * Bertujuan untuk menghemat resource server dan menjaga load aplikasi API.
   * @param {*} token
   * @param {*} page
   * @param {*} rows
   * @param {*} sort
   * @param {*} order
   * @param {*} filter_rules
   * @returns
   */
  async getCcdeposits(token, page, rows, sort, order, filter_rules) {
    var res = await axios({
      method: "get",
      headers: {
        JWT: token,
      },
      url: `${
        themain.baseSync
      }/ccdeposits?page=${page}&rows=${rows}&sort=${sort}&order=${order}&filter_rules=${JSON.stringify(
        filter_rules
      )}`,
    });
    return res.data.data;
  },

  async postDepositIDR(datas, token) {
    var res = await axios({
      headers: {
        JWT: token,
      },
      method: "post",
      url: `${themain.baseSync}/wallets_idr/deposit`,
      data: datas,
    });
    return res.data;
  },

  async postWithdrawIDR(datas, token) {
    var res = await axios({
      headers: {
        JWT: token,
      },
      method: "post",
      url: `${themain.baseSync}/wallets_idr/withdraw`,
      data: datas,
    });
    return res.data;
  },

  async postRequestOtp(token) {
    var res = await axios({
      method: "post",
      headers: {
        JWT: token,
      },
      url: `${themain.baseSync}/otps/create`,
    });
    return res.data;
  },

  async postEvmTokenWithdraw(
    token,
    otp,
    google_secret,
    chain_id,
    contract_address,
    currency,
    amount,
    withdraw_to,
    to_name,
    to_id_number,
    to_home_address
  ) {
    var res = await axios({
      method: "post",
      headers: {
        JWT: token,
      },
      url: `${themain.baseEvm}/evm/token-withdraw`,
      data: {
        otp,
        google_secret,
        chain_id,
        contract_address,
        currency,
        amount,
        withdraw_to,
        to_name,
        to_id_number,
        to_home_address,
      },
    });
    return res.data;
  },

  async postEvmCoinWithdraw(
    token,
    otp,
    google_secret,
    chain_id,
    currency,
    amount,
    withdraw_to,
    ccurrency_table_id,
    to_name,
    to_id_number,
    to_home_address
  ) {
    var res = await axios({
      method: "post",
      headers: {
        JWT: token,
      },
      url: `${themain.baseEvm}/evm/coin-withdraw`,
      data: {
        otp,
        google_secret,
        currency,
        amount,
        withdraw_to,
        chain_id,
        ccurrency_table_id,
        to_name,
        to_id_number,
        to_home_address,
      },
    });
    return res.data;
  },

  async getWithdraws(token, page, rows, sort, order, filter_rules) {
    var res = await axios({
      method: "get",
      headers: {
        JWT: token,
      },
      url: `${
        themain.baseSync
      }/withdraws?page=${page}&rows=${rows}&sort=${sort}&order=${order}&filter_rules=${JSON.stringify(
        filter_rules
      )}`,
    });
    return res.data.data;
  },

  async getMyTwoFaQR(token) {
    var res = await axios({
      method: "get",
      headers: {
        JWT: token,
      },
      url: `${themain.baseApi}/users/two-fa-qr-img`,
    });
    return res.data;
  },

  async postEvmTokenManualDeposit(
    token,
    chain_id,
    txhash,
    contract_address,
    currency
  ) {
    var res = await axios({
      method: "post",
      headers: {
        JWT: token,
      },
      url: `${themain.baseEvm}/blocks/erc20-deposit-manual`,
      data: {
        txhash,
        contract_address,
        currency,
        evm_chain_id: chain_id,
      },
    });
    return res.data;
  },

  async postEthManualDeposit(token, txhash, chain_name) {
    var evm_chain_id = 0;
    if (chain_name == "ethereum") {
      // evm_chain_id = process.env.VUE_APP_EVM_ETH_ID;
      var resA = await axios({
        url: `${themain.baseApi}/public_config/evm-chain-id?name=EVM_CHAIN_ID_ETH`,
      });
      if (resA.data.success == true) {
        evm_chain_id = resA.data.data;
      }
    } else {
      // evm_chain_id = process.env.VUE_APP_EVM_ARB_ID;
      var resB = await axios({
        url: `${themain.baseApi}/public_config/evm-chain-id?name=EVM_CHAIN_ID_ARB`,
      });
      if (resB.data.success == true) {
        evm_chain_id = resB.data.data;
      }
    }

    if (evm_chain_id == 0) {
      return { success: false, msg: "Chain ID not found" };
    }

    var res = await axios({
      method: "post",
      headers: {
        JWT: token,
      },
      url: `${themain.baseEvm}/blocks/coin-deposit-manual`,
      data: {
        txhash,
        currency: "ETH",
        evm_chain_id,
      },
    });
    return res.data;
  },

  async postBnbManualDeposit(token, txhash) {
    var evm_chain_id = 0;
    var resA = await axios({
      url: `${themain.baseApi}/public_config/evm-chain-id?name=EVM_CHAIN_ID_BNB`,
    });
    if (resA.data.success == true) {
      evm_chain_id = resA.data.data;
    }

    if (evm_chain_id == 0) {
      return { success: false, msg: "Chain ID not found" };
    }

    var res = await axios({
      method: "post",
      headers: {
        JWT: token,
      },
      url: `${themain.baseEvm}/blocks/coin-deposit-manual`,
      data: {
        txhash,
        currency: "BNB",
        evm_chain_id: evm_chain_id,
      },
    });
    return res.data;
  },

  async getEvmTokensByCcurrencyId(ccurrencyId) {
    var res = await axios({
      method: "get",
      url: `${themain.baseEvm}/block_evm_tokens/by-ccurrency-id?ccurrency_id=${ccurrencyId}`,
    });
    return res.data.datas;
  },

  async getBonusCurrentPeriod() {
    var res = await axios({
      method: "get",
      url: `${themain.baseSync}/bonus/current-period`,
    });
    // console.log("res", res);
    return res.data;
  },

  async getBonusOrderComplete(token, page, rows, sort, order, filter_rules) {
    var res = await axios({
      method: "get",
      headers: {
        JWT: token,
      },
      url: `${
        themain.baseSync
      }/bonus/order-complete?page=${page}&rows=${rows}&sort=${sort}&order=${order}&filter_rules=${JSON.stringify(
        filter_rules
      )}`,
    });
    // console.log("res.data", res.data);
    return res.data;
  },

  async getWalletsIdrDeposits(token, page, rows, sort, order, filter_rules) {
    var res = await axios({
      method: "get",
      headers: {
        JWT: token,
      },
      url: `${
        themain.baseSync
      }/wallets_idr/deposits?page=${page}&rows=${rows}&sort=${sort}&order=${order}&filter_rules=${JSON.stringify(
        filter_rules
      )}`,
    });
    return res.data.data;
  },

  async postWalletsIdrDeposit(token, depoidr_amount) {
    var res = await axios({
      method: "post",
      headers: {
        JWT: token,
      },
      url: `${themain.baseSync}/wallets_idr/deposit`,
      data: {
        depoidr_amount,
      },
    });
    return res.data;
  },

  async postWalletsIdrDepositFile(token, formData) {
    var res = await axios({
      method: "post",
      headers: {
        JWT: token,
        "Content-Type": "multipart/form-data",
      },
      url: `${themain.baseSync}/wallets_idr/deposit_file`,
      data: formData,
    });
    return res.data;
  },

  async getWalletsIdrWithdraws(token, page, rows, sort, order, filter_rules) {
    var res = await axios({
      method: "get",
      headers: {
        JWT: token,
      },
      url: `${
        themain.baseSync
      }/wallets_idr/withdraws?page=${page}&rows=${rows}&sort=${sort}&order=${order}&filter_rules=${JSON.stringify(
        filter_rules
      )}`,
    });
    return res.data.data;
  },

  async postWalletsIdrWithdraw(token, amount, otp, google_secret) {
    var res = await axios({
      method: "post",
      headers: {
        JWT: token,
      },
      url: `${themain.baseSync}/wallets_idr/withdraw`,
      data: {
        amount,
        otp,
        google_secret,
      },
    });
    return res.data;
  },

  async getWalletsBtcDeposits(token, page, rows, sort, order, filter_rules) {
    var res = await axios({
      method: "get",
      headers: {
        JWT: token,
      },
      url: `${
        themain.baseSync
      }/wallets_btc/deposits?page=${page}&rows=${rows}&sort=${sort}&order=${order}&filter_rules=${JSON.stringify(
        filter_rules
      )}`,
    });
    return res.data.data;
  },

  async getWalletsBtcWithdraws(token, page, rows, sort, order, filter_rules) {
    var res = await axios({
      method: "get",
      headers: {
        JWT: token,
      },
      url: `${
        themain.baseSync
      }/wallets_btc/withdraws?page=${page}&rows=${rows}&sort=${sort}&order=${order}&filter_rules=${JSON.stringify(
        filter_rules
      )}`,
    });
    return res.data.data;
  },

  async postWalletsBtcWithdraw(
    token,
    withdraw_to,
    to_name,
    to_id_number,
    to_home_address,
    amount,
    otp,
    google_secret
  ) {
    var res = await axios({
      method: "post",
      headers: {
        JWT: token,
      },
      url: `${themain.baseSync}/wallets_btc/withdraw`,
      data: {
        withdraw_to,
        amount,
        otp,
        google_secret,
        to_name,
        to_id_number,
        to_home_address,
      },
    });
    return res.data;
  },

  async getNotifs(token, page, rows, sort, order, filter_rules) {
    var res = await axios({
      method: "get",
      headers: {
        JWT: token,
      },
      url: `${
        themain.baseSync
      }/notifs?page=${page}&rows=${rows}&sort=${sort}&order=${order}&filter_rules=${JSON.stringify(
        filter_rules
      )}`,
    });
    return res.data.data;
  },

  async postClearNotifs(token) {
    var res = await axios({
      method: "post",
      headers: {
        JWT: token,
      },
      url: `${themain.baseSync}/notifs/clear-all`,
    });
    return res.data;
  },

  async postKytCcdeposit(token, data) {
    var res = await axios({
      method: "post",
      headers: {
        JWT: token,
      },
      url: `${themain.baseSync}/ccdeposits/kyt`,
      data,
    });
    return res.data;
  },

  async postUserDataUpdate(token, data) {
    var res = await axios({
      method: "post",
      headers: {
        JWT: token,
      },
      url: `${themain.baseSync}/users/data-update`,
      data,
    });
    return res.data;
  },

  async postAgreementUserAgree(token) {
    var res = await axios({
      method: "post",
      headers: {
        JWT: token,
      },
      url: `${themain.baseSync}/users/user-agree`,
    });
    return res.data;
  },

  async getBankList() {
    const res = await axios({
      method: "get",
      url: `${themain.basePg}/api/payment/available_disbursements_bank`,
    });
    return res.data;
  },

  async getWalletIdrXenditWdFee() {
    const res = await axios({
      method: "get",
      url: `${themain.baseSync}/wallets_idr/xendit-wd-fee`,
    });
    return res.data;
  },

  async postContactUs(data) {
    var res = await axios({
      method: "post",
      url: `${themain.baseSync}/users/contact-us`,
      data,
    });
    return res.data;
  },

  async getConfig() {
    var res = await axios({
      method: "get",
      headers: {
        "x-api-key": themain.xApiKey,
      },
      url: `${themain.configApi}/config`,
    });
    return res.data;
  },

  async getUserAgreementText(token) {
    var res = await axios({
      method: "get",
      headers: {
        JWT: token,
      },
      url: `${themain.baseSync}/users/user-agreement-text`,
    });
    return res.data;
  },

  async getServerStatus() {
    var res = await axios({
      method: "get",
      url: `${themain.baseApi}/auth/status`,
    });
    return res.data;
  },
};

export default utama;
