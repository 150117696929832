<template>
  <div>
    <TopNav />

    <div class="container">
      <div class="row text-center text-dark mt-4">
        <div class="col" v-if="user.orang_kyc_status == 'approve'">
          <h1 class="text-white">Selamat, KYC telah selesai dan disetujui</h1>

          <br />
          <p>
            Anda bisa langsung ke halaman pertukaran
            <router-link :to="themain.defaultMarketPair">disini</router-link>
          </p>
        </div>
        <div class="col" v-else>
          <h1
            class="text-white"
            style="margin-top: 200px; margin-bottom: 200px"
          >
            Data terikirim ke server, <br />
            Silahkan menunggu proses verifikasi
          </h1>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
// node_modules
import Cookies from "js-cookie";

// applibs
import themain from "@/applibs/themain.js";
import thestore from "@/applibs/thestore.js";

// components
import TopNav from "@/components/global/TopNav.vue";
import Footer from "@/components/global/Footer.vue";

export default {
  components: {
    TopNav,
    Footer,
  },
  data() {
    return {
      themain,
      token: Cookies.get("jwt"),
      usid: Cookies.get("usid"),

      user: {},
    };
  },

  async beforeMount() {
    var self = this;
    // cek expire token
    if (self.token) {
      var res = await thestore.postCheckToken(self.token);
      if (res.success == false) {
        Cookies.remove("jwt");
        Cookies.remove("usid");
        self.$router.go();
      }
    }
  },

  async mounted() {
    var self = this;

    // ambil data dia ketika login
    if (self.token != null && self.token != "") {
      var res = await thestore.getMyProfileData(self.token);
      self.user = res;
    } else {
      self.$router.push("/");
    }

    // checking KYC step
    await self.kycChecing();
  },

  methods: {
    async kycChecing() {
      var self = this;
      var res = await thestore.postKycCheckingStep(self.token);
      if (res.kyc_step != 3) {
        if (res.kyc_type == "Indonesia") {
          if (res.kyc_step == 0) {
            // selesai nol
            self.$router.push("/kyc/id/satu");
          } else if (res.kyc_step == 1) {
            // selesai 1
            self.$router.push("/kyc/id/dua");
          } else if (res.kyc_step == 2) {
            // selesai 2
            self.$router.push("/kyc/id/tiga");
          }
        } else {
          if (res.kyc_step == 0) {
            // selesai nol
            self.$router.push("/kyc/global/satu");
          } else if (res.kyc_step == 1) {
            // selesai 1
            self.$router.push("/kyc/global/dua");
          } else if (res.kyc_step == 2) {
            // selesai 2
            self.$router.push("/kyc/global/tiga");
          }
        }
      }
    },
  },
};
</script>
